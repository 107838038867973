@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import '@seamapi/react/dist/index.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 640px;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// Custom seam-components styles
.seam-components .seam-device-details .seam-content-header {
  display: none!important;
}

// for mobile only
@media (max-width: 640px) {
  .seam-components .seam-table-row .seam-title {
    font-weight: 600;
    font-size: 14px!important;
    line-height: 132%;
  }

  .seam-status-text{
    font-size: 12px!important;
  }

  .seam-components .seam-device-table .seam-table-row .seam-body-cell .seam-bottom .seam-device-model {
    font-weight: 400;
    font-size: 12px!important;
  }

  .seam-components .seam-table-title {
    font-size: 16px!important;
    line-height: 132%;
  }
}