@import "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap";
@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.seam-components {
  font-family: Source Sans Pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Open Sans, sans-serif;
}

.seam-components *, .seam-components :before, .seam-components :after {
  box-sizing: border-box;
}

.seam-components html, .seam-components body, .seam-components div, .seam-components span, .seam-components object, .seam-components iframe, .seam-components figure, .seam-components h1, .seam-components h2, .seam-components h3, .seam-components h4, .seam-components h5, .seam-components h6, .seam-components p, .seam-components blockquote, .seam-components pre, .seam-components a, .seam-components code, .seam-components em, .seam-components img, .seam-components small, .seam-components strike, .seam-components strong, .seam-components sub, .seam-components sup, .seam-components tt, .seam-components b, .seam-components u, .seam-components i, .seam-components ol, .seam-components ul, .seam-components li, .seam-components fieldset, .seam-components form, .seam-components label, .seam-components table, .seam-components caption, .seam-components tbody, .seam-components tfoot, .seam-components thead, .seam-components tr, .seam-components th, .seam-components td, .seam-components main, .seam-components canvas, .seam-components embed, .seam-components footer, .seam-components header, .seam-components nav, .seam-components section, .seam-components video {
  vertical-align: baseline;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
}

.seam-components footer, .seam-components header, .seam-components nav, .seam-components section, .seam-components main {
  display: block;
}

.seam-components body {
  line-height: 1;
}

.seam-components ol, .seam-components ul {
  list-style: none;
}

.seam-components blockquote, .seam-components q {
  quotes: none;
}

.seam-components blockquote:before, .seam-components blockquote:after, .seam-components q:before, .seam-components q:after {
  content: "";
  content: none;
}

.seam-components table {
  border-collapse: collapse;
  border-spacing: 0;
}

.seam-components input {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}

.seam-components h1, .seam-components h2, .seam-components h3, .seam-components h4, .seam-components h5, .seam-components h6 {
  font-weight: 600;
}

.seam-components .seam-caption {
  color: #9da1a9;
}

.seam-components .seam-text-danger {
  color: #e36857;
}

.seam-components html {
  color: #232b3a;
}

.seam-components .seam-truncated-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: hidden;
}

.seam-components .seam-table-header {
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 0 16px;
  display: flex;
}

.seam-components .seam-table-header .seam-body {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.seam-components .seam-table-header .seam-table-header-loading-wrap {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  left: 0;
}

.seam-components .seam-table-title {
  font-size: 21px;
  line-height: 132%;
}

.seam-components .seam-table-title .seam-caption {
  font-size: 16px;
  line-height: 134%;
}

.seam-components .seam-table-row {
  border-bottom: 1px solid #eee;
  height: 72px;
  padding: 4px;
  display: flex;
}

.seam-components .seam-table-row:hover {
  background: #f4f6f8;
}

.seam-components .seam-table-row .seam-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 132%;
}

.seam-components .seam-table-cell {
  align-items: center;
  padding: 4px;
  display: flex;
}

.seam-components .seam-table-empty-placeholder {
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 18px;
  line-height: 132%;
  display: flex;
}

.seam-components .seam-table-filter-bar {
  background: #f1f3f4;
  align-items: center;
  padding: 6px 16px;
  display: flex;
}

.seam-components .seam-table-filter-bar .seam-clear-filters-button {
  border: 0;
  margin-left: 6px;
  padding: 0;
  background: none !important;
}

.seam-components .seam-table-filter-bar .seam-clear-filters-button svg {
  scale: .8333;
}

.seam-components .seam-table-filter-bar .seam-clear-filters-button svg * {
  fill: #6e7179;
}

.seam-components .seam-table-filter-item {
  border: 1px solid #f1f3f4;
  border-radius: 5px;
  align-items: center;
  padding: 2px 8px 2px 5px;
  font-size: 14px;
  line-height: 134%;
  display: flex;
}

.seam-components .seam-table-filter-item svg {
  scale: .75;
}

.seam-components .seam-table-filter-item.seam-clickable {
  cursor: pointer;
}

.seam-components .seam-table-filter-item.seam-clickable:hover {
  background: rgba(255, 255, 255, .65);
  border: 1px solid #d5d8dc;
}

.seam-components .seam-table-filter-item.seam-selected {
  background: #fff;
  border: 1px solid #d5d8dc;
}

.seam-components .seam-text-field > .seam-main {
  background: #fff;
  border: 1px solid #d5d8dc;
  border-radius: 8px;
  align-items: center;
  padding: 0 8px;
  display: flex;
}

.seam-components .seam-text-field > .seam-main:hover {
  border-color: #232b3a;
}

.seam-components .seam-text-field > .seam-main:focus-within {
  border-color: #007afc;
}

.seam-components .seam-text-field .seam-text-field-input {
  border: 0;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  font-family: Source Sans Pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Open Sans, sans-serif;
  font-size: 16px;
}

.seam-components .seam-text-field .seam-text-field-input:focus {
  outline: none;
}

.seam-components .seam-text-field .seam-adornment {
  align-items: center;
  display: flex;
}

.seam-components .seam-text-field .seam-adornment svg {
  scale: .8333;
}

.seam-components .seam-text-field .seam-adornment.seam-start {
  margin-right: 4px;
}

.seam-components .seam-text-field .seam-adornment button {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  display: flex;
}

.seam-components .seam-text-field .seam-adornment button:hover {
  background-color: #e9edef;
}

.seam-components .seam-text-field .seam-adornment button.seam-hidden {
  opacity: 0;
  visibility: hidden;
}

.seam-components .seam-text-field > .seam-helper-text {
  margin-top: 4px;
}

.seam-components .seam-text-field.seam-disabled > .seam-main {
  cursor: not-allowed;
  background: #ececec;
  border-color: #d5d8dc;
}

.seam-components .seam-text-field.seam-disabled > .seam-main > .seam-text-field-input {
  cursor: not-allowed;
}

.seam-components .seam-text-field.seam-large > .seam-main {
  padding: 0 12px;
}

.seam-components .seam-text-field.seam-large > .seam-main > .seam-text-field-input {
  height: 48px;
}

.seam-components .seam-text-field.seam-error > .seam-main {
  border: 1px solid #e36857;
}

.seam-components .seam-text-field.seam-error > .seam-helper-text {
  color: #e36857;
}

.seam-components .seam-search-text-field svg * {
  fill: #9da1a9;
}

.seam-components .seam-input-label {
  cursor: default;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 134%;
}

.seam-components .seam-form-field {
  margin-bottom: 24px;
}

.seam-components .seam-form-field > .seam-input-label {
  -webkit-user-select: none;
  user-select: none;
}

.seam-components .seam-form-field > .seam-radio-field {
  margin-top: 4px;
}

.seam-components .seam-btn {
  cursor: pointer;
  font-family: Source Sans Pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Open Sans, sans-serif;
  font-weight: 600;
}

.seam-components .seam-btn.seam-btn-small {
  border-radius: 6px;
  padding: 4px 10px;
  font-size: 14px;
  line-height: 22px;
}

.seam-components .seam-btn.seam-btn-medium {
  border-radius: 7px;
  padding: 6px 16px;
  font-size: 16px;
  line-height: 26px;
}

.seam-components .seam-btn.seam-btn-large {
  border-radius: 8px;
  padding: 8px 22px;
  font-size: 19px;
  line-height: 30px;
}

.seam-components .seam-btn.seam-btn-solid {
  color: #fff;
  background: #007afc;
  border: 1px solid #007afc;
}

.seam-components .seam-btn.seam-btn-solid:hover {
  background: #006ee3;
  border-color: #006ee3;
}

.seam-components .seam-btn.seam-btn-solid.seam-btn-disabled, .seam-components .seam-btn.seam-btn-solid:disabled {
  background: #c1dfff;
  border-color: #c1dfff;
}

.seam-components .seam-btn.seam-btn-outline {
  color: #007afc;
  background: none;
  border: 1px solid #7ebcff;
}

.seam-components .seam-btn.seam-btn-outline:hover {
  background: #f2f8ff;
}

.seam-components .seam-btn.seam-btn-outline.seam-btn-disabled, .seam-components .seam-btn.seam-btn-outline:disabled {
  color: #7ebcff;
  background: none;
}

.seam-components .seam-btn.seam-btn-neutral {
  color: #6e7179;
  border: 1px solid #b9bec5;
}

.seam-components .seam-btn.seam-btn-neutral:hover {
  background: #f1f3f4;
  border-color: #b9bec5;
}

.seam-components .seam-btn.seam-btn-neutral.seam-btn-disabled, .seam-components .seam-btn.seam-btn-neutral:disabled {
  color: #b9bec5;
  background: #fff;
  border-color: #d5d8dc;
}

.seam-components .seam-btn.seam-btn-disabled, .seam-components .seam-btn:disabled {
  cursor: not-allowed;
}

.seam-components .seam-icon-btn {
  cursor: pointer;
  background: #fff;
  border: 1px solid rgba(0, 122, 252, .5);
  border-radius: 6px;
  width: 30px;
  height: 30px;
  padding: 2px;
  line-height: 0;
}

.seam-components .seam-icon-btn:hover {
  background: rgba(0, 122, 252, .08);
}

.seam-components .seam-icon-btn svg {
  scale: .75;
}

.seam-components .seam-icon-btn svg * {
  fill: #007afc;
}

.seam-components .seam-text-btn {
  color: #007afc;
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
}

.seam-components .seam-text-btn:hover {
  text-decoration: underline;
}

.seam-components .seam-text-btn.seam-btn-disabled, .seam-components .seam-text-btn:disabled {
  cursor: not-allowed;
  text-decoration: none;
}

.seam-components .seam-content-header {
  justify-content: center;
  align-items: center;
  height: 56px;
  margin: 0 16px;
  display: flex;
  position: relative;
}

.seam-components .seam-content-header.seam-content-header-contained {
  margin: 0;
}

.seam-components .seam-content-header .seam-back-icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(4px, -50%);
}

.seam-components .seam-content-header .seam-title {
  color: #6e7179;
  font-size: 16px;
  line-height: 134%;
}

.seam-components .seam-content-header .seam-subheading {
  text-align: center;
  color: #6e7179;
  font-size: 12px;
  line-height: 140%;
  display: block;
}

.seam-components .seam-padded-container {
  padding: 0 16px;
}

.seam-components .seam-detail-sections {
  flex-direction: column;
  gap: 32px;
  display: flex;
}

.seam-components .seam-detail-section-wrap {
  flex-direction: column;
  gap: 6px;
  display: flex;
}

.seam-components .seam-detail-section {
  width: 100%;
}

.seam-components .seam-detail-label-wrap {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  width: 100%;
  margin-bottom: 8px;
  display: flex;
}

.seam-components .seam-detail-label {
  color: #6e7179;
  font-size: 16px;
  font-weight: 400;
  line-height: 134%;
}

.seam-components .seam-detail-group {
  border: 1px solid #d5d8dc;
  border-radius: 8px;
  overflow: hidden;
}

.seam-components .seam-detail-section-footer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.seam-components .seam-detail-section-footer-content-text {
  color: #b9bec5;
  font-size: 12px;
}

.seam-components .seam-detail-section-tooltip-inner-content {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.seam-components .seam-detail-row, .seam-components .seam-accordion-row {
  background-color: #fff;
}

.seam-components .seam-detail-row:not(:last-of-type), .seam-components .seam-accordion-row:not(:last-of-type) {
  border-bottom: 1px solid #eee;
}

.seam-components .seam-detail-row.seam-detail-row-clickable, .seam-components .seam-accordion-row.seam-detail-row-clickable {
  cursor: pointer;
  transition: all .2s;
}

.seam-components .seam-detail-row.seam-detail-row-clickable:hover, .seam-components .seam-accordion-row.seam-detail-row-clickable:hover {
  background-color: #f1f3f4;
}

.seam-components .seam-detail-row, .seam-components .seam-accordion-row-trigger {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  padding: 8px 16px;
  display: flex;
}

.seam-components .seam-detail-row-label-wrap {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  display: flex;
}

.seam-components .seam-detail-row-label-column {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: flex;
}

.seam-components .seam-detail-row-label-block {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  display: flex;
}

.seam-components .seam-row-label {
  font-size: 16px;
  font-weight: 600;
  line-height: 118%;
}

.seam-components .seam-detail-row-empty-label {
  color: #9da1a9;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 118%;
}

.seam-components .seam-row-sublabel {
  color: #6e7179;
  font-size: 14px;
  font-weight: 400;
  line-height: 118%;
}

.seam-components .seam-row-sublabel.seam-row-sublabel-text-default {
  color: #232b3a;
}

.seam-components .seam-detail-row-hstack {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.seam-components .seam-detail-row-end-alignment {
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  padding-top: 12px;
  display: flex;
}

.seam-components .seam-detail-row-rotated-icon {
  transform: rotate(-90deg);
}

.seam-components .seam-detail-row-right-area-content {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.seam-components .seam-accordion-row {
  overflow: hidden;
}

.seam-components .seam-accordion-row .seam-accordion-row-trigger {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  cursor: pointer;
  background-color: #fff;
  border: none;
  width: 100%;
  font-family: inherit;
  font-size: 16px;
}

.seam-components .seam-accordion-row .seam-accordion-row-content {
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-height: 0;
  transition: all .3s ease-in-out;
  display: flex;
}

.seam-components .seam-accordion-row[aria-expanded="true"] .seam-accordion-row-content {
  opacity: 1;
  max-height: 128px;
}

.seam-components .seam-accordion-row[aria-expanded="true"] .seam-accordion-icon-wrap {
  transform: rotate(180deg);
}

.seam-components .seam-accordion-row[aria-expanded="true"] .seam-row-trigger-right-content {
  opacity: 0;
  transform: scale(.95);
}

.seam-components .seam-accordion-row .seam-accordion-row-inner-content {
  width: 100%;
  height: 128px;
  padding: 0 16px;
  overflow: hidden;
}

.seam-components .seam-accordion-row .seam-row-inner-wrap {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;
}

.seam-components .seam-accordion-row .seam-row-trigger-right-content {
  transition: all .2s ease-in-out;
}

.seam-components .seam-accordion-row .seam-accordion-icon-wrap {
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  transition: transform .2s ease-in-out;
  display: flex;
}

.seam-components .seam-invisible {
  display: none !important;
}

@media only screen and (min-width: 768px) {
  .seam-components .seam-md-flex {
    display: flex !important;
  }

  .seam-components .seam-md-block {
    display: block !important;
  }

  .seam-components .seam-md-invisible {
    display: none !important;
  }
}

.seam-components .seam-loading-toast-centered-wrap {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.seam-components .seam-loading-toast {
  will-change: transform;
  pointer-events: none;
  z-index: 9;
  background: #f1f3f4;
  border-radius: 6px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 32px;
  padding: 0 10px;
  transition: all .2s cubic-bezier(.22, 1, .36, 1);
  animation: .2s cubic-bezier(.22, 1, .36, 1) scale-in;
  display: flex;
  position: absolute;
  box-shadow: 0 1px 5px rgba(24, 29, 37, .06), 0 0 1px rgba(24, 29, 37, .25);
}

@keyframes scale-in {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: scale(.5);
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}

.seam-components .seam-loading-toast.seam-loading-toast-hide {
  opacity: 0;
  visibility: hidden;
  transform: scale(.5);
}

.seam-components .seam-loading-toast .seam-loading-toast-icon-wrap {
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  display: flex;
}

.seam-components .seam-loading-toast .seam-loading-toast-text {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.seam-components .seam-lock-icon * {
  fill: #6e7179;
}

.seam-components .seam-menu-bg {
  z-index: 999999;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  inset: 0;
}

.seam-components .seam-menu-hidden {
  display: none;
}

.seam-components .seam-menu-visible {
  display: flex;
}

.seam-components .seam-menu-content {
  background: #fff;
  border-radius: 8px;
  width: 250px;
  padding: 8px 0;
  position: absolute;
  box-shadow: 0 0 1px rgba(0, 0, 0, .3), 0 2px 16px 2px rgba(15, 22, 28, .15);
}

.seam-components .seam-menu-content .seam-menu-item {
  cursor: pointer;
  align-items: center;
  height: 36px;
  padding: 0 16px;
  font-size: 16px;
  line-height: 134%;
  display: flex;
}

.seam-components .seam-menu-content .seam-menu-item:hover {
  background: #f4f6f8;
}

.seam-components .seam-menu-content .seam-divider {
  background: #eee;
  height: 1px;
  margin: 8px 0;
}

.seam-components .seam-menu-shadow {
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
}

.seam-components .seam-checkbox {
  cursor: pointer;
  align-items: center;
  gap: 8px;
  padding: 6px 0;
  display: flex;
}

.seam-components .seam-checkbox > .seam-checkbox-icon {
  width: 24px;
  height: 24px;
}

.seam-components .seam-checkbox > input {
  display: none;
}

.seam-components .seam-checkbox > .seam-checkbox-label {
  font-size: 16px;
  line-height: 150%;
}

.seam-components .seam-radio-field {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.seam-components .seam-radio-field > .seam-radio {
  cursor: pointer;
  align-items: center;
  gap: 4px;
  padding: 0 8px;
  display: flex;
}

.seam-components .seam-radio-field > .seam-radio > input {
  display: none;
}

.seam-components .seam-radio-field > .seam-radio > .seam-radio-icon {
  width: 24px;
  height: 24px;
}

.seam-components .seam-radio-field > .seam-radio > .seam-radio-label {
  font-size: 16px;
  line-height: 134%;
}

.seam-components .seam-tooltip {
  position: relative;
}

.seam-components .seam-tooltip .seam-tooltip-button {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 90px;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  margin: 0;
  padding: 0;
  display: flex;
}

.seam-components .seam-tooltip .seam-tooltip-button .seam-tooltip-button-icon {
  justify-content: center;
  align-items: center;
  transition: opacity .15s ease-in-out;
  display: flex;
  position: absolute;
}

.seam-components .seam-tooltip .seam-tooltip-button .seam-tooltip-button-icon-hover, .seam-components .seam-tooltip .seam-tooltip-button:hover .seam-tooltip-button-icon-default, .seam-components .seam-tooltip .seam-tooltip-button:focus .seam-tooltip-button-icon-default {
  opacity: 0;
}

.seam-components .seam-tooltip .seam-tooltip-button:hover .seam-tooltip-button-icon-hover, .seam-components .seam-tooltip .seam-tooltip-button:focus .seam-tooltip-button-icon-hover {
  opacity: 1;
}

.seam-components .seam-tooltip .seam-tooltip-button:focus {
  outline: none;
}

.seam-components .seam-tooltip .seam-tooltip-popover {
  will-change: transform, opacity, visibility;
  transform-origin: 0 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: -1;
  background: #fff;
  border-radius: 8px;
  gap: 10px;
  width: 230px;
  padding: 10px;
  transition: all .1s ease-in-out;
  display: flex;
  position: absolute;
  bottom: 28px;
  left: 2px;
  transform: scale(.9);
  box-shadow: 0 2px 16px 2px rgba(15, 22, 28, .15), 0 0 1px rgba(0, 0, 0, .3);
}

.seam-components .seam-tooltip .seam-tooltip-popover[aria-expanded="true"] {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  z-index: 9;
  transform: scale(1);
}

.seam-components .seam-tooltip .seam-tooltip-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 134%;
}

.seam-components .seam-snackbar-wrap {
  pointer-events: none;
  z-index: 9;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  display: flex;
  position: fixed;
  bottom: 0;
}

.seam-components .seam-snackbar {
  pointer-events: auto;
  will-change: transform;
  opacity: 0;
  visibility: hidden;
  background: #30373a;
  border-radius: 12px;
  align-items: flex-start;
  gap: 10px;
  padding: 16px;
  transition: all .2s cubic-bezier(.83, 0, .17, 1);
  display: flex;
  position: absolute;
  bottom: 24px;
  transform: translateY(24px);
  box-shadow: 0 2px 12px rgba(0, 0, 0, .25);
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(24px);
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.seam-components .seam-snackbar.seam-snackbar-visible {
  opacity: 1;
  visibility: visible;
  animation: .2s cubic-bezier(.83, 0, .17, 1) fade-in-up;
  transform: translateY(0);
}

.seam-components .seam-snackbar .seam-snackbar-message-wrap {
  max-width: 300px;
  display: flex;
}

.seam-components .seam-snackbar .seam-snackbar-message {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 134%;
}

.seam-components .seam-snackbar .seam-snackbar-actions-wrap {
  flex-direction: row;
  align-items: center;
  gap: 16px;
  height: 24px;
  display: flex;
}

.seam-components .seam-snackbar .seam-snackbar-action {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  transition: opacity .2s ease-in-out;
  display: flex;
}

.seam-components .seam-snackbar .seam-snackbar-action:hover {
  opacity: .75;
}

.seam-components .seam-snackbar .seam-snackbar-action .seam-snackbar-action-label {
  color: #6ac1ff;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  line-height: .8;
}

.seam-components .seam-snackbar .seam-snackbar-close-button {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  transition: opacity .2s ease-in-out;
  display: flex;
}

.seam-components .seam-snackbar .seam-snackbar-close-button:hover {
  opacity: .75;
}

.seam-components .seam-spinner {
  border: 2px solid #007afc;
  border-top-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: .5s linear infinite spin;
  display: inline-block;
}

.seam-components .seam-spinner.size-small {
  border-width: 2px;
  width: 16px;
  height: 16px;
}

.seam-components .seam-spinner.size-medium {
  border-width: 3px;
  width: 24px;
  height: 24px;
}

.seam-components .seam-spinner.size-large {
  border-width: 3px;
  width: 32px;
  height: 32px;
}

.seam-components .seam-switch-container {
  align-items: center;
  gap: 8px;
  display: flex;
}

.seam-components .seam-switch-container .seam-switch-label {
  color: #b9bec5;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 118%;
}

.seam-components .seam-switch-container .seam-switch {
  cursor: pointer;
  position: relative;
}

.seam-components .seam-switch-container .seam-switch .seam-switch-slider:before {
  content: "";
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, .25));
  background-color: #fafafa;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: all .4s;
  position: absolute;
  bottom: -3px;
  left: 0;
}

.seam-components .seam-switch-container .seam-switch .seam-switch-slider {
  background-color: #ccc;
  border-radius: 20px;
  width: 34px;
  height: 14px;
  transition: all .4s;
  inset: 0;
}

.seam-components .seam-switch-container .seam-switch.seam-switch-checked .seam-switch-slider:before {
  background-color: #007afc;
  transform: translateX(14px);
}

.seam-components .seam-switch-container .seam-switch.seam-switch-checked .seam-switch-slider {
  background-color: #c1dfff;
}

.seam-components .seam-time-zone-picker .seam-manual-time-zone-checkbox {
  margin-bottom: 8px;
}

.seam-components .seam-time-zone-picker > .seam-time-zone-select {
  background: #fff;
  border: 1px solid #d5d8dc;
  border-radius: 8px;
  width: 100%;
  height: 48px;
  padding: 0 12px;
  font-size: 16px;
}

.seam-components .seam-tab-set {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.seam-components .seam-tab-set .seam-tab-set-buttons {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 8px;
  display: flex;
  position: relative;
}

.seam-components .seam-tab-set .seam-tab-set-highlight {
  background-color: #007afc;
  border-radius: 6px 6px 0 0;
  height: 4px;
  transition: all .24s cubic-bezier(.2, 0, .38, .9);
  position: absolute;
  bottom: 0;
}

.seam-components .seam-tab-set .seam-tab-button {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: none;
  color: #9da1a9;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  justify-content: center;
  align-items: center;
  width: 140px;
  padding-top: 4px;
  padding-bottom: 12px;
  font-size: 14px;
  transition: all .24s ease-in-out;
  display: flex;
}

.seam-components .seam-tab-set .seam-tab-button:hover, .seam-components .seam-tab-set .seam-tab-button.seam-tab-button-active {
  color: #232b3a;
}

.seam-components .seam-device-details .seam-body {
  flex-direction: column;
  gap: 16px;
  margin: 0 24px 24px;
  display: flex;
}

.seam-components .seam-device-details .seam-body.seam-body-no-margin {
  gap: 0;
  margin: 0;
}

.seam-components .seam-device-details .seam-body .seam-box {
  border: 1px solid #d5d8dc;
  border-radius: 8px;
}

.seam-components .seam-device-details .seam-body .seam-box .seam-content {
  padding: 12px 12px 12px 16px;
}

.seam-components .seam-device-details .seam-body .seam-box .seam-content.seam-access-codes {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px 12px 16px;
  display: flex;
}

.seam-components .seam-device-details .seam-body .seam-box .seam-content.seam-access-codes:hover {
  background: #f4f6f8;
}

.seam-components .seam-device-details .seam-body .seam-box .seam-content.seam-access-code-length {
  border-top: 1px solid #d5d8dc;
}

.seam-components .seam-device-details .seam-body .seam-box .seam-content.seam-lock-status {
  justify-content: space-between;
  display: flex;
}

.seam-components .seam-device-details .seam-body .seam-box .seam-content.seam-lock-status > .seam-right {
  align-items: center;
  display: flex;
}

.seam-components .seam-device-details .seam-body .seam-box .seam-content .seam-label {
  color: #6e7179;
  font-size: 14px;
  line-height: 134%;
}

.seam-components .seam-device-details .seam-body .seam-box .seam-content .seam-value {
  font-size: 16px;
  font-weight: 600;
  line-height: 134%;
  display: block;
}

.seam-components .seam-device-details .seam-body .seam-contained-summary {
  padding: 0 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .08), 0 1px rgba(0, 0, 0, .1);
}

.seam-components .seam-device-details .seam-body .seam-summary {
  background: #f1f3f4;
  border-radius: 16px;
  padding: 24px 16px;
}

.seam-components .seam-device-details .seam-body .seam-summary .seam-content {
  align-items: center;
  gap: 20px;
  display: flex;
}

.seam-components .seam-device-details .seam-body .seam-summary .seam-content .seam-image {
  align-items: center;
  width: 100px;
  height: 100px;
  display: flex;
}

.seam-components .seam-device-details .seam-body .seam-summary .seam-content .seam-image img {
  max-width: 100%;
}

.seam-components .seam-device-details .seam-body .seam-summary .seam-content .seam-info > .seam-label {
  color: #6e7179;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 134%;
}

.seam-components .seam-device-details .seam-body .seam-summary .seam-content .seam-info .seam-device-name {
  margin-bottom: 16px;
  font-size: 21px;
  font-weight: 600;
  line-height: 132%;
}

.seam-components .seam-device-details .seam-body .seam-summary .seam-content .seam-info .seam-properties {
  grid-template-columns: min-content 1fr;
  grid-auto-rows: 1fr;
  gap: 4px 28px;
  font-size: 14px;
  line-height: 134%;
  display: grid;
}

.seam-components .seam-device-details .seam-body .seam-summary .seam-content .seam-info .seam-properties .seam-label {
  color: #9da1a9;
  white-space: nowrap;
}

.seam-components .seam-device-details .seam-body .seam-summary .seam-content .seam-info .seam-properties .seam-status-text {
  color: #6e7179;
}

.seam-components .seam-device-details .seam-body .seam-summary .seam-content .seam-info .seam-properties svg {
  margin-right: 8px;
  scale: .75;
}

.seam-components .seam-device-details .seam-body .seam-summary .seam-content .seam-info .seam-properties > * {
  align-items: center;
  display: flex;
}

.seam-components .seam-device-details .seam-body .seam-icon-cell {
  flex: 0;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  display: flex;
}

.seam-components .seam-device-details .seam-body .seam-icon-cell > div {
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.seam-components .seam-device-details .seam-body .seam-name-cell {
  flex: 1;
}

.seam-components .seam-device-details .seam-body .seam-action-cell {
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  display: flex;
}

.seam-components .seam-device-details .seam-thermostat-device-details {
  margin-top: 8px;
}

.seam-components .seam-device-table .seam-search-text-field {
  width: 170px;
}

.seam-components .seam-device-table .seam-table-row {
  cursor: pointer;
}

.seam-components .seam-device-table .seam-table-row .seam-offline-fade {
  opacity: .4;
}

.seam-components .seam-device-table .seam-table-row .seam-image-cell {
  width: 64px;
  height: 64px;
  padding: 4px;
}

.seam-components .seam-device-table .seam-table-row .seam-image-cell img {
  width: 100%;
}

.seam-components .seam-device-table .seam-table-row .seam-body-cell {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  overflow: hidden;
}

.seam-components .seam-device-table .seam-table-row .seam-body-cell .seam-title {
  max-width: 380px;
}

.seam-components .seam-device-table .seam-table-row .seam-body-cell .seam-bottom {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.seam-components .seam-device-table .seam-table-row .seam-body-cell .seam-bottom .seam-device-model {
  color: #6e7179;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 0 190px;
  font-size: 14px;
  font-weight: 400;
  line-height: 134%;
  overflow: hidden;
}

.seam-components .seam-device-table .seam-table-row .seam-body-cell .seam-bottom .seam-device-statuses {
  color: #6e7179;
  align-items: center;
  font-size: 14px;
  line-height: 134%;
  display: flex;
}

.seam-components .seam-device-table .seam-table-row .seam-body-cell .seam-bottom .seam-device-statuses > div {
  align-items: center;
  margin-right: 16px;
  display: flex;
}

.seam-components .seam-device-table .seam-table-row .seam-body-cell .seam-bottom .seam-device-statuses > div svg {
  scale: .75;
}

.seam-components .seam-access-code-details > .seam-summary {
  background: #f1f3f4;
  border-radius: 16px;
  margin: 0 24px 16px;
}

.seam-components .seam-access-code-details > .seam-summary > .seam-top {
  padding: 24px 16px;
}

.seam-components .seam-access-code-details > .seam-summary > .seam-top.seam-top-has-alerts {
  padding-bottom: 8px;
}

.seam-components .seam-access-code-details > .seam-summary > .seam-top > .seam-label {
  color: #6e7179;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 134%;
}

.seam-components .seam-access-code-details > .seam-summary > .seam-top > .seam-access-code-name {
  margin-bottom: 8px;
  font-size: 21px;
  font-weight: 600;
  line-height: 132%;
}

.seam-components .seam-access-code-details > .seam-summary > .seam-top > .seam-code {
  background: #fff;
  border: 1px solid #9da1a9;
  border-radius: 8px;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
  line-height: 134%;
  display: flex;
}

.seam-components .seam-access-code-details > .seam-summary > .seam-top > .seam-code > span {
  padding: 10px 16px;
}

.seam-components .seam-access-code-details > .seam-summary > .seam-top > .seam-code > .seam-icon-btn {
  border: 0;
  border-left: 1px solid #eee;
  border-radius: 0 8px 8px 0;
  flex: 0 0 48px;
  height: auto;
  padding: 16px 10px;
  overflow: visible;
}

.seam-components .seam-access-code-details > .seam-summary > .seam-top > .seam-code > .seam-icon-btn:hover {
  background: #f4f6f8;
}

.seam-components .seam-access-code-details > .seam-summary > .seam-top > .seam-code > .seam-icon-btn svg {
  scale: .8333;
}

.seam-components .seam-access-code-details > .seam-summary > .seam-top .seam-duration {
  color: #6e7179;
  font-size: 14px;
  line-height: 134%;
}

.seam-components .seam-access-code-details > .seam-summary > .seam-top .seam-duration .seam-label {
  color: #232b3a;
  font-weight: 600;
}

.seam-components .seam-access-code-details > .seam-summary .seam-access-code-device {
  border-top: 1px solid #dde0e2;
  align-items: center;
  padding: 8px 16px 8px 8px;
  display: flex;
}

.seam-components .seam-access-code-details > .seam-summary .seam-access-code-device .seam-device-image {
  width: 72px;
  height: 72px;
  margin-right: 16px;
}

.seam-components .seam-access-code-details > .seam-summary .seam-access-code-device .seam-device-image img {
  width: 100%;
  max-height: 100%;
}

.seam-components .seam-access-code-details > .seam-summary .seam-access-code-device .seam-body {
  flex: 1;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 134%;
}

.seam-components .seam-access-code-details .seam-actions {
  gap: 8px;
  margin-bottom: 16px;
  padding: 0 24px;
  display: flex;
}

.seam-components .seam-access-code-details .seam-details {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  padding: 0 24px;
  font-size: 16px;
  line-height: 134%;
}

.seam-components .seam-access-code-details .seam-details .seam-row {
  border-top: 1px solid rgba(0, 0, 0, .12);
  padding: 16px;
  display: flex;
}

.seam-components .seam-access-code-details .seam-details .seam-row .seam-heading {
  width: 112px;
}

.seam-components .seam-access-code-details .seam-details .seam-row .seam-content {
  flex: 1;
  display: flex;
}

.seam-components .seam-access-code-details .seam-details .seam-row .seam-content.seam-code-id {
  justify-content: space-between;
  display: flex;
}

.seam-components .seam-access-code-details .seam-details .seam-row .seam-content.seam-code-id .seam-icon-btn {
  border: 0;
}

.seam-components .seam-access-code-details .seam-details .seam-row .seam-content.seam-code-id .seam-icon-btn:hover {
  background: none;
}

.seam-components .seam-access-code-details .seam-details .seam-row .seam-content.seam-code-id .seam-icon-btn svg {
  scale: .8333;
}

.seam-components .seam-access-code-details .seam-details .seam-row .seam-content .seam-times {
  flex: 1;
  font-size: 16px;
  line-height: 134%;
  display: flex;
}

.seam-components .seam-access-code-details .seam-details .seam-row .seam-content .seam-times .seam-label {
  color: #9da1a9;
}

.seam-components .seam-access-code-details .seam-details .seam-row .seam-content .seam-times > div div {
  margin-bottom: 4px;
}

.seam-components .seam-access-code-details .seam-details .seam-row .seam-content .seam-times > div:first-child {
  flex: 0 160px;
}

.seam-components .seam-access-code-form > .seam-main {
  padding: 24px 24px 0;
}

.seam-components .seam-access-code-form > .seam-main .seam-code-text-field {
  margin-bottom: 4px;
}

.seam-components .seam-access-code-form > .seam-main .seam-code-field > .seam-text-field {
  margin-bottom: 8px;
}

.seam-components .seam-access-code-form > .seam-main .seam-code-field > .seam-bottom {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.seam-components .seam-access-code-form > .seam-main .seam-code-field > .seam-bottom.has-hints {
  justify-content: space-between;
}

.seam-components .seam-access-code-form > .seam-main .seam-code-field > .seam-bottom .seam-requirements {
  color: #9da1a9;
  padding-left: 20px;
  font-size: 14px;
  line-height: 134%;
  list-style: disc;
}

.seam-components .seam-access-code-form > .seam-main .seam-code-field > .seam-bottom .seam-requirements.seam-error {
  color: #e36857;
}

.seam-components .seam-access-code-form > .seam-main .seam-selected-times {
  align-items: center;
  gap: 24px;
  margin-top: 4px;
  margin-left: 36px;
  display: flex;
}

.seam-components .seam-access-code-form > .seam-main .seam-selected-times > div {
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  display: grid;
}

.seam-components .seam-access-code-form > .seam-main .seam-selected-times > div > span {
  font-size: 14px;
  line-height: 134%;
}

.seam-components .seam-access-code-form > .seam-main .seam-selected-times > div > .seam-label {
  color: #9da1a9;
}

.seam-components .seam-access-code-form > .seam-main .seam-selected-times > div > .seam-time {
  color: #6e7179;
}

.seam-components .seam-access-code-form > .seam-main .seam-unknown-error {
  text-align: center;
  color: #e36857;
  margin-bottom: 16px;
  line-height: 134%;
}

.seam-components .seam-access-code-form > .seam-main .seam-actions {
  justify-content: center;
  gap: 8px;
  display: flex;
}

.seam-components .seam-access-code-form > .seam-main .seam-actions .seam-btn {
  width: 96px;
}

.seam-components .seam-access-code-form .seam-schedule-picker .seam-content {
  padding: 0 24px;
}

.seam-components .seam-access-code-form .seam-schedule-picker .seam-content .seam-time-zone {
  align-items: center;
  margin-bottom: 24px;
  font-size: 14px;
  display: flex;
}

.seam-components .seam-access-code-form .seam-schedule-picker .seam-content .seam-time-zone .seam-label {
  color: #9da1a9;
  margin-right: 4px;
}

.seam-components .seam-access-code-form .seam-schedule-picker .seam-content .seam-time-zone .seam-selected {
  color: #007afc;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.seam-components .seam-access-code-form .seam-schedule-picker .seam-content .seam-time-zone .seam-selected svg {
  transform: translate(-6px, 2px);
}

.seam-components .seam-access-code-form .seam-schedule-picker .seam-content .seam-time-zone .seam-selected svg * {
  fill: #007afc;
}

.seam-components .seam-access-code-form .seam-access-code-time-zone-picker .seam-content {
  padding: 0 24px;
}

.seam-components .seam-alerts {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
}

.seam-components .seam-alerts.seam-alerts-space-top {
  margin-top: 16px;
}

.seam-components .seam-alerts.seam-alerts-padded {
  padding: 0 16px 24px;
}

.seam-components .seam-alert {
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  min-height: 54px;
  padding: 8px 16px;
  display: flex;
}

.seam-components .seam-alert .seam-alert-action {
  -webkit-appearance: none;
  appearance: none;
  white-space: nowrap;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 6px;
  height: 32px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}

.seam-components .seam-alert .seam-alert-action:hover {
  background-color: #f4f6f8;
}

.seam-components .seam-alert.seam-warning-alert {
  background-color: #fff2e0;
  border: 1px solid #f8d8be;
}

.seam-components .seam-alert.seam-warning-alert .seam-alert-action {
  color: #ca8852;
  border: 1px solid #ca8852;
}

.seam-components .seam-alert.seam-error-alert {
  background-color: #ffefef;
  border: 1px solid #f1d4d4;
}

.seam-components .seam-alert.seam-error-alert .seam-alert-action {
  color: #cb7d7d;
  border: 1px solid #cb7d7d;
}

.seam-components .seam-alert.seam-alert-space-top {
  margin-top: 16px;
}

.seam-components .seam-alert .seam-alert-icon-wrap {
  padding: 7px 8px 7px 0;
}

.seam-components .seam-alert .seam-alert-icon {
  width: 24px;
  height: 24px;
}

.seam-components .seam-alert .seam-alert-content {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.seam-components .seam-alert .seam-alert-message-wrap {
  padding: 8px 0;
}

.seam-components .seam-alert .seam-alert-message-wrap .seam-alert-message {
  color: #232b3a;
  font-size: 14px;
  font-weight: 400;
  line-height: 134%;
}

@media only screen and (max-width: 500px) {
  .seam-components .seam-alert {
    flex-direction: column;
    justify-content: flex-start;
  }

  .seam-components .seam-alert .seam-alert-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
  }

  .seam-components .seam-alert .seam-alert-action-wrap, .seam-components .seam-alert .seam-alert-action-wrap .seam-alert-action {
    width: 100%;
  }
}

.seam-components .seam-supported-device-table-content-wrap {
  background: #fff;
}

.seam-components .seam-supported-device-table-filter-area {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  display: flex;
}

.seam-components .seam-supported-device-table-filter-area .seam-filters-wrap {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.seam-components .seam-supported-device-table-filter-area .seam-supported-device-table-filter-area-search-bar-wrap {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.seam-components .seam-supported-device-table-filter-menu {
  width: 100%;
  padding: 8px 16px;
}

.seam-components .seam-supported-device-table-filter-menu .seam-filter-menu-row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.seam-components .seam-supported-device-table-filter-menu .seam-filter-menu-row:not(:last-of-type) {
  margin-bottom: 8px;
}

.seam-components .seam-supported-device-table-filter-menu .seam-filter-menu-row .seam-filter-checkbox-label {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.seam-components .seam-supported-device-table-filter-menu .seam-filter-menu-row .seam-filter-checkbox {
  -webkit-appearance: checkbox;
  appearance: checkbox;
}

.seam-components .seam-supported-device-table-filter-menu .seam-supported-device-table-filter-menu-wrap {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.seam-components .seam-supported-device-table-filter-menu .seam-supported-device-table-filter-menu-wrap button {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  cursor: pointer;
  background-color: #e9edef;
  border: 0;
  border-radius: 4px;
  margin: 0;
  padding: 4px 24px 4px 8px;
  position: relative;
}

.seam-components .seam-supported-device-table-filter-menu .seam-supported-device-table-filter-menu-wrap button:hover {
  background-color: #dde0e2;
}

.seam-components .seam-supported-device-table-filter-menu .seam-supported-device-table-filter-menu-wrap button span {
  font-size: 14px;
  font-weight: 500;
}

.seam-components .seam-supported-device-table-filter-menu .seam-supported-device-table-filter-menu-wrap button svg {
  position: absolute;
  top: 2px;
}

.seam-components .seam-manufacturer-section {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 32px;
  padding: 8px 16px 16px;
  display: flex;
  position: relative;
}

.seam-components .seam-manufacturer-section .seam-manufacturer-annotation-box {
  background-color: rgba(0, 122, 252, .08);
  border-radius: 6px;
  flex-wrap: wrap;
  align-content: center;
  align-self: stretch;
  align-items: center;
  gap: 0 8px;
  padding: 8px;
  display: flex;
}

.seam-components .seam-manufacturer-section .seam-manufacturer-annotation-box .seam-annotation {
  color: #6e7179;
  flex-wrap: wrap;
  flex: 1 0 0;
  align-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  font-size: 14px;
  line-height: 120%;
  display: flex;
}

.seam-components .seam-manufacturer-section .seam-header {
  align-items: center;
  height: 64px;
  display: flex;
}

.seam-components .seam-manufacturer-section .seam-header .seam-manufacturer-image {
  box-sizing: content-box;
  width: 48px;
  height: 48px;
  margin-right: 8px;
  padding: 0 8px;
}

.seam-components .seam-manufacturer-section .seam-header .seam-manufacturer-name {
  flex: 1;
  font-size: 21px;
  line-height: 132%;
}

.seam-components .seam-manufacturer-section .seam-header .chevron {
  margin-right: 24px;
  transform: rotate(90deg);
}

.seam-components .seam-manufacturer-section .show-all-devices-button {
  cursor: pointer;
  color: #6e7179;
  z-index: 3;
  background: #fff;
  border: 0;
  border-radius: 30px;
  align-items: center;
  gap: 2px;
  height: 30px;
  padding: 5px 24px 4px 16px;
  font-size: 14px;
  line-height: 115%;
  display: flex;
  position: absolute;
  bottom: -13px;
  left: 50%;
  transform: translate(-50%, -47px);
  box-shadow: 0 1px 1px rgba(16, 27, 37, .1), 0 1px 8px rgba(46, 69, 82, .12);
}

.seam-components .seam-manufacturer-section .show-all-devices-button svg {
  transform: rotate(90deg);
}

.seam-components .seam-manufacturer-section .hidden-devices-overlay {
  z-index: 2;
  background: linear-gradient(rgba(255, 255, 255, 0) 10.17%, #fff 86.98%);
  width: 100%;
  height: 64px;
  position: absolute;
  bottom: 0;
}

.seam-components .seam-manufacturer-section.can-expand .seam-header {
  cursor: pointer;
}

.seam-components .seam-manufacturer-section.can-expand .seam-header:hover {
  background: #fafafa;
}

.seam-components .seam-manufacturer-section.expanded {
  margin-bottom: 96px;
}

.seam-components .seam-manufacturer-section.expanded .seam-header .chevron {
  transform: rotate(270deg);
}

.seam-components .seam-manufacturer-section.expanded .show-all-devices-button {
  transform: translate(-50%, 16px);
}

.seam-components .seam-manufacturer-section.expanded .show-all-devices-button svg {
  transform: rotate(270deg);
}

.seam-components .seam-supported-device-table-content-state-block {
  background-color: #f1f3f4;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 32px 8px;
  display: flex;
}

.seam-components .seam-supported-device-table-content-message-row {
  height: auto !important;
}

.seam-components .seam-supported-device-table-content-message {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 32px 0;
  display: flex;
}

.seam-components .seam-supported-device-table-content-message p {
  margin: 0;
  line-height: 1;
}

.seam-components .seam-supported-device-table-content-message button {
  cursor: pointer;
  margin-top: 8px;
}

.seam-components .seam-supported-device-table-content {
  table-layout: auto;
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;
}

.seam-components .seam-supported-device-table-content .seam-row {
  border-bottom: 1px solid #e9edef;
  height: 64px;
  display: flex;
}

.seam-components .seam-supported-device-table-content .seam-row:first-child {
  border-top: 1px solid #e9edef;
}

.seam-components .seam-supported-device-table-content .seam-row .seam-col {
  padding: 8px;
  display: flex;
}

.seam-components .seam-supported-device-table-content .seam-status-pill {
  border-radius: 16px;
  padding: 4px 8px;
  display: inline-flex;
}

.seam-components .seam-supported-device-table-content .seam-status-pill span {
  letter-spacing: .55px;
  font-size: 11px;
  line-height: 134%;
}

.seam-components .seam-supported-device-table-content .seam-status-pill.status-green {
  color: #0d8841;
  background-color: rgba(98, 232, 139, .3);
}

.seam-components .seam-supported-device-table-content .seam-status-pill.status-blue {
  color: #1653b8;
  background-color: rgba(125, 192, 241, .3);
}

.seam-components .seam-supported-device-table-content .seam-status-pill.status-unknown {
  color: #6e7179;
  font-size: 12px;
  line-height: 118%;
}

.seam-components .seam-supported-device-table-content .seam-status-pill:not(.status-unknown) {
  font-weight: 600;
}

.seam-components .seam-supported-device-table-content .seam-device-image-col {
  align-items: center;
}

.seam-components .seam-supported-device-table-content .seam-device-image-col .seam-image-box {
  width: 48px;
  height: 48px;
}

.seam-components .seam-supported-device-table-content .seam-device-image-col .seam-image-box img {
  width: 100%;
}

.seam-components .seam-supported-device-table-content .seam-model-col {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

.seam-components .seam-supported-device-table-content .seam-model-col .seam-model-name {
  font-size: 14px;
}

.seam-components .seam-supported-device-table-content .seam-model-col .seam-model-name .seam-truncated-text {
  width: 270px;
}

.seam-components .seam-supported-device-table-content .seam-model-col .seam-model-id {
  color: #9da1a9;
  font-size: 12px;
}

.seam-components .seam-supported-device-table-content .seam-model-col .seam-model-id .seam-truncated-text {
  width: 220px;
}

.seam-components .seam-supported-device-table-content .seam-model-col .seam-model-id .seam-dot-divider {
  color: #d5d8dc;
  margin: 0 4px;
}

@media only screen and (min-width: 450px) {
  .seam-components .seam-supported-device-table-content .seam-model-col .seam-model-name {
    font-size: 16px;
  }

  .seam-components .seam-supported-device-table-content .seam-model-col .seam-model-id {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  .seam-components .seam-supported-device-table-content .seam-model-col {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .seam-components .seam-supported-device-table-content .seam-model-col .seam-model-name {
    flex: 1;
    font-size: 14px;
  }

  .seam-components .seam-supported-device-table-content .seam-model-col .seam-model-id {
    flex: 0 0 40%;
    justify-content: flex-end;
    font-size: 14px;
    display: flex;
  }

  .seam-components .seam-supported-device-table-content .seam-model-col .seam-model-id .seam-truncated-text {
    text-align: right;
  }
}

.seam-components .seam-supported-device-table-content .seam-status-col {
  text-align: center;
  align-items: center;
  width: 70px;
}

.seam-components .seam-supported-device-table-filter-menu-content {
  max-height: 300px;
  overflow: auto;
}

.seam-components .supported-device-table-manufacturer-keys {
  font-size: 18px;
}

.seam-components .supported-device-table-manufacturer-keys .seam-manufacturer-key {
  display: table-row;
}

.seam-components .supported-device-table-manufacturer-keys .seam-manufacturer-key-value {
  display: table-cell;
}

.seam-components .supported-device-table-manufacturer-keys .seam-copy-button {
  cursor: pointer;
  padding-left: 10px;
  display: table-cell;
}

.seam-components .seam-climate-setting-control-group {
  flex-direction: column;
  gap: 16px;
  width: 100%;
  display: flex;
}

.seam-components .seam-climate-setting-control-group .seam-climate-setting-slider-container {
  justify-content: center;
  display: flex;
}

.seam-components .seam-temperature-control-group {
  flex-direction: column;
  gap: 24px;
  display: flex;
}

.seam-components .seam-temperature-control-group-block {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
}

.seam-components .seam-temperature-control {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
  display: flex;
}

.seam-components .seam-temperature-range-wrap {
  --thumb-size: 20px;
  --track-height: 4px;
  --temperature-min: 0;
  --temperature-max: 100;
  --temperature-current: 50;
  --temperature-range: calc(var(--temperature-max)  - var(--temperature-min));
  --temperature-ratio: calc((var(--temperature-current)  - var(--temperature-min)) / var(--temperature-range));
  --slider-position: calc(.5 * var(--thumb-size)  + var(--temperature-ratio) * (100% - var(--thumb-size)));
  --slider-position-rtl: calc(100% - var(--slider-position));
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.seam-components input[type="range"].seam-temperature-range {
  -webkit-appearance: none;
  appearance: none;
  width: 160px;
}

.seam-components input[type="range"].seam-temperature-range:focus {
  outline: none;
}

.seam-components input[type="range"].seam-temperature-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: calc(var(--track-height) * .5 - var(--thumb-size) * .5);
}

.seam-components input[type="range"].seam-temperature-range::-ms-thumb {
  box-sizing: border-box;
  margin-top: 0;
}

.seam-components input[type="range"].seam-temperature-range::-ms-track {
  box-sizing: border-box;
}

.seam-components input[type="range"].seam-temperature-range::-ms-fill-upper {
  background: none;
  border-color: rgba(0, 0, 0, 0);
}

.seam-components input[type="range"].seam-temperature-range::-ms-fill-lower {
  height: var(--track-height);
  border-style: none;
  border-color: rgba(0, 0, 0, 0);
  border-right-width: 0;
  border-radius: .5em 0 0 .5em;
  margin: 0;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]::-webkit-slider-thumb {
  width: var(--thumb-size);
  height: var(--thumb-size);
  box-shadow: none;
  cursor: grab;
  background: #fff;
  border: 2px solid #fc8e28;
  border-radius: 1em;
  transition: all .15s ease-in-out;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]::-webkit-slider-thumb:hover {
  background-color: #fff2e0;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]::-webkit-slider-thumb:active {
  cursor: grabbing;
  background-color: #fc8e28 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .15) !important;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]::-moz-range-thumb {
  width: var(--thumb-size);
  height: var(--thumb-size);
  box-shadow: none;
  cursor: grab;
  background: #fff;
  border: 2px solid #fc8e28;
  border-radius: 1em;
  transition: all .15s ease-in-out;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]::-moz-range-thumb:hover {
  background-color: #fff2e0;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]::-moz-range-thumb:active {
  cursor: grabbing;
  background-color: #fc8e28 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .15) !important;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]::-ms-thumb {
  width: var(--thumb-size);
  height: var(--thumb-size);
  box-shadow: none;
  cursor: grab;
  background: #fff;
  border: 2px solid #fc8e28;
  border-radius: 1em;
  transition: all .15s ease-in-out;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]::-ms-thumb:hover {
  background-color: #fff2e0;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]::-ms-thumb:active {
  cursor: grabbing;
  background-color: #fc8e28 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .15) !important;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]:focus::-webkit-slider-thumb {
  background-color: #fff2e0;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]:focus::-moz-range-thumb {
  background-color: #fff2e0;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]:focus::-ms-thumb {
  background-color: #fff2e0;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]::-webkit-slider-runnable-track {
  height: var(--track-height);
  box-shadow: none;
  background: linear-gradient(#fc8e28, #fc8e28) 0 / var(--slider-position) 100% no-repeat, #d5d8dc;
  border: none;
  border-radius: .5em;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]::-moz-range-track {
  height: var(--track-height);
  box-shadow: none;
  background: linear-gradient(#fc8e28, #fc8e28) 0 / var(--slider-position) 100% no-repeat, #d5d8dc;
  border: none;
  border-radius: .5em;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]::-ms-track {
  height: var(--track-height);
  box-shadow: none;
  background: linear-gradient(#fc8e28, #fc8e28) 0 / var(--slider-position) 100% no-repeat, #d5d8dc;
  border: none;
  border-radius: .5em;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="heat"]::-ms-fill-lower {
  background: #fc8e28;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]::-webkit-slider-thumb {
  width: var(--thumb-size);
  height: var(--thumb-size);
  box-shadow: none;
  cursor: grab;
  background: #fff;
  border: 2px solid #6b95ff;
  border-radius: 1em;
  transition: all .15s ease-in-out;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]::-webkit-slider-thumb:hover {
  background-color: #e7f2ff;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]::-webkit-slider-thumb:active {
  cursor: grabbing;
  background-color: #6b95ff !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .15) !important;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]::-moz-range-thumb {
  width: var(--thumb-size);
  height: var(--thumb-size);
  box-shadow: none;
  cursor: grab;
  background: #fff;
  border: 2px solid #6b95ff;
  border-radius: 1em;
  transition: all .15s ease-in-out;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]::-moz-range-thumb:hover {
  background-color: #e7f2ff;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]::-moz-range-thumb:active {
  cursor: grabbing;
  background-color: #6b95ff !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .15) !important;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]::-ms-thumb {
  width: var(--thumb-size);
  height: var(--thumb-size);
  box-shadow: none;
  cursor: grab;
  background: #fff;
  border: 2px solid #6b95ff;
  border-radius: 1em;
  transition: all .15s ease-in-out;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]::-ms-thumb:hover {
  background-color: #e7f2ff;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]::-ms-thumb:active {
  cursor: grabbing;
  background-color: #6b95ff !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .15) !important;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]:focus::-webkit-slider-thumb {
  background-color: #e7f2ff;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]:focus::-moz-range-thumb {
  background-color: #e7f2ff;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]:focus::-ms-thumb {
  background-color: #e7f2ff;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]::-webkit-slider-runnable-track {
  height: var(--track-height);
  box-shadow: none;
  background: linear-gradient(#6b95ff, #6b95ff) 100% / calc(var(--slider-position-rtl)) 100% no-repeat, #d5d8dc;
  border: none;
  border-radius: .5em;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]::-moz-range-track {
  height: var(--track-height);
  box-shadow: none;
  background: linear-gradient(#6b95ff, #6b95ff) 100% / calc(var(--slider-position-rtl)) 100% no-repeat, #d5d8dc;
  border: none;
  border-radius: .5em;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]::-ms-track {
  height: var(--track-height);
  box-shadow: none;
  background: linear-gradient(#6b95ff, #6b95ff) 100% / calc(var(--slider-position-rtl)) 100% no-repeat, #d5d8dc;
  border: none;
  border-radius: .5em;
}

.seam-components input[type="range"].seam-temperature-range[data-variant="cool"]::-ms-fill-lower {
  background: #6b95ff;
}

.seam-components .seam-floating-temperature {
  top: -28px;
  left: calc(var(--slider-position)  - 10px);
  position: absolute;
}

.seam-components .seam-floating-temperature .seam-floating-temperature-value {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}

.seam-components .seam-floating-temperature .seam-floating-temperature-unit {
  color: #b9bec5;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.seam-components .seam-temperature-stepper-button {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #d5d8dc;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  transition: all .1s ease-in-out;
  display: flex;
}

.seam-components .seam-temperature-stepper-button:focus {
  outline: none;
}

.seam-components .seam-temperature-stepper-button:hover, .seam-components .seam-temperature-stepper-button:focus {
  background-color: #f1f3f4;
  border-color: #9da1a9;
}

.seam-components .seam-temperature-stepper-button:active {
  background-color: #dde0e2;
  border-color: #6e7179;
}

.seam-components .seam-climate-setting-status {
  color: #6e7179;
  white-space: nowrap;
  align-items: center;
  gap: 4px;
  line-height: 118%;
  display: flex;
}

.seam-components .seam-climate-setting-status-icon {
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.seam-components .seam-thermostat-card {
  background-color: #f1f3f4;
  border-radius: 16px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 16px;
  display: flex;
}

.seam-components .seam-thermostat-card .seam-thermostat-card-content {
  flex-direction: row;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.seam-components .seam-thermostat-card .seam-thermostat-card-image-wrap {
  padding: 24px;
}

.seam-components .seam-thermostat-card .seam-thermostat-card-image-wrap img {
  object-fit: contain;
  width: 80px;
  height: 80px;
}

.seam-components .seam-thermostat-card .seam-thermostat-heading-wrap {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
}

.seam-components .seam-thermostat-card .seam-thermostat-card-heading {
  white-space: nowrap;
  font-size: 21px;
  font-weight: 600;
  line-height: 132%;
}

.seam-components .seam-thermostat-card .seam-thermostat-temperature-toggle {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  color: #6e7179;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #d5d8dc;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 22px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  display: flex;
}

.seam-components .seam-thermostat-card .seam-thermostat-card-details {
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
}

.seam-components .seam-thermostat-card .seam-thermostat-properties-wrap {
  display: flex;
}

.seam-components .seam-thermostat-card .seam-thermostat-properties {
  grid-template-columns: auto auto;
  gap: 8px 16px;
  width: auto;
  display: grid;
}

.seam-components .seam-thermostat-card .seam-thermostat-property-block {
  flex-direction: row;
  gap: 8px;
  display: flex;
}

.seam-components .seam-thermostat-card .seam-thermostat-property-block.seam-thermostat-property-icon-block {
  gap: 4px;
}

.seam-components .seam-thermostat-card .seam-thermostat-property-label {
  color: #9da1a9;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 118%;
}

.seam-components .seam-thermostat-card .seam-thermostat-property-value {
  color: #6e7179;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 118%;
}

.seam-components .seam-thermostat-card .seam-thermostat-property-icon {
  width: 18px;
  height: 18px;
}

.seam-components .seam-thermostat-card .seam-thermostat-property-tag {
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 18px;
  padding: 0 8px;
  display: flex;
}

.seam-components .seam-thermostat-card .seam-thermostat-property-tag.seam-thermostat-property-tag-cooling {
  background-color: #6b95ff;
}

.seam-components .seam-thermostat-card .seam-thermostat-property-tag.seam-thermostat-property-tag-heating {
  background-color: #fc8e28;
}

.seam-components .seam-thermostat-card .seam-thermostat-property-tag.seam-thermostat-property-tag-fan {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #b9bec5;
}

.seam-components .seam-thermostat-card .seam-thermostat-property-tag.seam-thermostat-property-tag-fan .seam-thermostat-property-tag-label {
  color: #6e7179;
}

.seam-components .seam-thermostat-card .seam-thermostat-property-tag.seam-thermostat-property-tag-off {
  background-color: rgba(0, 0, 0, 0);
}

.seam-components .seam-thermostat-card .seam-thermostat-property-tag.seam-thermostat-property-tag-off .seam-thermostat-property-tag-label {
  color: #6e7179;
}

.seam-components .seam-thermostat-card .seam-thermostat-property-tag .seam-thermostat-property-tag-label {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
}

.seam-components .seam-thermo-mode-menu .seam-menu-content {
  max-width: 180px;
  box-shadow: 0 2px 16px 2px rgba(15, 22, 28, .15), 0 0 1px rgba(0, 0, 0, .3);
}

.seam-components .seam-thermo-mode-menu .seam-thermo-mode-menu-item {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.seam-components .seam-thermo-mode-menu .seam-thermo-mode-menu-item-block {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: flex;
}

.seam-components .seam-thermo-mode-menu-icon {
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  display: flex;
}

.seam-components .seam-fan-mode-menu-button {
  cursor: pointer;
  background: #fff;
  border: 1px solid #d5d8dc;
  border-radius: 6px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 130px;
  height: 32px;
  transition: all .2s;
  display: flex;
}

.seam-components .seam-fan-mode-menu-button:hover {
  border-color: #9da1a9;
}

.seam-components .seam-fan-mode-menu-button:active {
  background: #dde0e2;
  border: 1px solid #9da1a9;
}

.seam-components .seam-fan-mode-menu-button .seam-fan-mode-menu-button-block {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.seam-components .seam-fan-mode-menu-button .seam-fan-mode-menu-button-text {
  color: #6e7179;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
}

.seam-components .seam-climate-mode-menu-button {
  cursor: pointer;
  background: #fff;
  border: 1px solid #d5d8dc;
  border-radius: 6px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 72px;
  height: 32px;
  padding: 4px 8px;
  transition: all .2s;
  display: flex;
}

.seam-components .seam-climate-mode-menu-button:hover {
  border-color: #9da1a9;
}

.seam-components .seam-climate-mode-menu-button:focus {
  outline-color: #9da1a9;
}

.seam-components .seam-climate-mode-menu-button:active {
  background-color: #dde0e2;
  border-color: #9da1a9;
}

.seam-components .seam-climate-mode-menu-button-icon {
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  display: flex;
}

.seam-components .seam-thermostat-mutation-status {
  opacity: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  transition: all .2s ease-in-out;
  display: flex;
}

.seam-components .seam-thermostat-mutation-status.is-visible {
  opacity: 1;
}

.seam-components .seam-thermostat-mutation-status-icon {
  opacity: .25;
}

.seam-components .seam-thermostat-mutation-status-label {
  color: #b9bec5;
  font-size: 14px;
}

.seam-components .seam-table .seam-search-text-field {
  width: 170px;
}

.seam-components .seam-table .seam-table-header .seam-left {
  align-items: center;
  display: flex;
}

.seam-components .seam-table .seam-table-header .seam-left .seam-add-button {
  margin-left: 8px;
}

.seam-components .seam-table .seam-table-row {
  cursor: pointer;
}

.seam-components .seam-table .seam-table-row .seam-icon-cell {
  flex: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.seam-components .seam-table .seam-table-row .seam-icon-cell > div {
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 40px;
  margin-right: 4px;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .seam-components .seam-table .seam-table-row .seam-icon-cell > div {
    width: 40px;
    margin-right: 4px;
  }
}

.seam-components .seam-table .seam-table-row .seam-name-cell {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  overflow: hidden;
}

.seam-components .seam-table .seam-table-row .seam-name-cell .seam-title {
  max-width: 380px;
}

.seam-components .seam-table .seam-table-row .seam-name-cell .seam-details {
  color: #6e7179;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 134%;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .seam-components .seam-table .seam-table-row .seam-name-cell .seam-details {
    font-size: 14px;
  }
}

.seam-components .seam-table .seam-table-row .seam-name-cell .seam-details .seam-device-name {
  width: auto;
  max-width: 190px;
}

.seam-components .seam-table .seam-table-row .seam-name-cell .seam-details .seam-dot-divider {
  color: #d5d8dc;
  margin: 0 4px;
}

.seam-components .seam-table .seam-table-row .seam-action-cell {
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .seam-components .seam-table .seam-table-row .seam-action-cell {
    margin-right: 12px;
  }
}

.seam-components .seam-table .seam-table-row .seam-issue-icon-wrap {
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.seam-components .seam-table .seam-grayed-out {
  opacity: .4;
}

.seam-components .seam-table-action-menu .seam-delete-confirmation {
  text-align: center;
  padding: 8px 16px;
}

.seam-components .seam-table-action-menu .seam-delete-confirmation .seam-actions {
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
  display: flex;
}

.seam-components .seam-table-action-menu .seam-menu-item-copy {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.seam-components .seam-table-action-menu .seam-menu-item-copy svg {
  scale: .75;
}

.seam-components .seam-table-action-menu .seam-menu-item-copy svg * {
  fill: #007afc;
}

.seam-components .seam-noise-sensor-activity-list {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.seam-components .seam-noise-sensor-event-item {
  border-bottom: 1px solid #eee;
  gap: 40px;
  width: 100%;
  min-height: 64px;
  padding: 12px 8px 12px 16px;
  display: flex;
}

.seam-components .seam-noise-sensor-event-item-column-wrap {
  flex-direction: row;
  flex: .5;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;
  display: flex;
}

.seam-components .seam-noise-sensor-event-item-datetime-wrap {
  color: #6e7179;
  font-size: 14px;
  line-height: 134%;
}

.seam-components .seam-noise-sensor-event-item-date, .seam-components .seam-noise-sensor-event-item-time, .seam-components .seam-noise-sensor-event-item-context-label, .seam-components .seam-noise-sensor-event-item-context-sublabel {
  white-space: nowrap;
}

.seam-components .seam-noise-sensor-event-item-context-wrap {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.seam-components .seam-noise-sensor-event-item-context-label {
  color: #232b3a;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 14px;
  overflow: hidden;
}

.seam-components .seam-noise-sensor-event-item-context-sublabel {
  color: #9da1a9;
  font-size: 14px;
}

.seam-components .seam-noise-sensor-event-item-right-block {
  flex: .5;
  justify-content: flex-end;
  align-items: center;
  gap: 18px;
  display: flex;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

:host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

input:where([type="button"]) {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

input:where([type="reset"]) {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root {
  --k-device-pixel-ratio: 1;
  --k-hairline-color: rgba(0, 0, 0, .2);
  --k-rtl-reverse: 1;
  --k-color-ios-primary: 250 87 0;
  --k-color-ios-primary-tint: 255 112 36;
  --k-color-ios-primary-shade: 209 73 0;
  --k-color-md-light-primary: 169 56 0;
  --k-color-md-light-on-primary: 255 255 255;
  --k-color-md-light-primary-container: 255 219 207;
  --k-color-md-light-on-primary-container: 56 13 0;
  --k-color-md-light-secondary: 119 87 76;
  --k-color-md-light-on-secondary: 255 255 255;
  --k-color-md-light-secondary-container: 255 219 207;
  --k-color-md-light-on-secondary-container: 44 22 13;
  --k-color-md-light-surface: 255 251 255;
  --k-color-md-light-on-surface: 32 26 24;
  --k-color-md-light-surface-variant: 245 222 214;
  --k-color-md-light-on-surface-variant: 83 67 62;
  --k-color-md-light-outline: 133 115 109;
  --k-color-md-light-outline-variant: 216 194 187;
  --k-color-md-light-surface-1: 251 241 242;
  --k-color-md-light-surface-2: 248 235 235;
  --k-color-md-light-surface-3: 246 230 227;
  --k-color-md-light-surface-4: 245 228 224;
  --k-color-md-light-surface-5: 243 224 219;
  --k-color-md-dark-primary: 255 181 155;
  --k-color-md-dark-on-primary: 91 26 0;
  --k-color-md-dark-primary-container: 129 41 0;
  --k-color-md-dark-on-primary-container: 255 219 207;
  --k-color-md-dark-secondary: 231 189 176;
  --k-color-md-dark-on-secondary: 68 42 33;
  --k-color-md-dark-secondary-container: 93 64 53;
  --k-color-md-dark-on-secondary-container: 255 219 207;
  --k-color-md-dark-surface: 32 26 24;
  --k-color-md-dark-on-surface: 237 224 220;
  --k-color-md-dark-surface-variant: 83 67 62;
  --k-color-md-dark-on-surface-variant: 216 194 187;
  --k-color-md-dark-outline: 160 141 134;
  --k-color-md-dark-outline-variant: 83 67 62;
  --k-color-md-dark-surface-1: 43 34 31;
  --k-color-md-dark-surface-2: 50 38 34;
  --k-color-md-dark-surface-3: 57 43 38;
  --k-color-md-dark-surface-4: 59 45 40;
  --k-color-md-dark-surface-5: 63 48 42;
  --k-color-primary: 250 87 0;
  --k-color-brand-primary: 250 87 0;
  --k-color-brand-red: 255 0 0;
  --k-color-brand-gray: 169 169 169;
  --k-color-brand-black: 0 0 0;
  --k-color-brand-green: 0 255 0;
}

[dir="rtl"] {
  --k-rtl-reverse: -1;
}

.dark {
  --k-hairline-color: rgba(255, 255, 255, .15);
  color: #fff;
  background-color: #000;
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 1.25dppx) {
  :root {
    --k-device-pixel-ratio: 1.25;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  :root {
    --k-device-pixel-ratio: 1.5;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.75), (min-resolution: 1.75dppx) {
  :root {
    --k-device-pixel-ratio: 1.75;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  :root {
    --k-device-pixel-ratio: 2;
  }
}

@media (-webkit-min-device-pixel-ratio: 2.25), (min-resolution: 2.25dppx) {
  :root {
    --k-device-pixel-ratio: 2.25;
  }
}

@media (-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 2.5dppx) {
  :root {
    --k-device-pixel-ratio: 2.5;
  }
}

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
  :root {
    --k-device-pixel-ratio: 3;
  }
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.k-ios, .ios {
  font-family: -apple-system, SF Pro Text, SF UI Text, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.k-material, .md {
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Noto, Helvetica, Arial, sans-serif;
}

:root {
  --k-touch-ripple-opacity: .15;
  --k-touch-ripple-black: rgba(0, 0, 0, var(--k-touch-ripple-opacity));
  --k-touch-ripple-white: rgba(255, 255, 255, var(--k-touch-ripple-opacity));
  --k-touch-ripple-color: var(--k-touch-ripple-black);
}

.k-touch-ripple-wave {
  pointer-events: none;
  z-index: -1;
  background-color: rgba(0, 0, 0, .15);
  background-color: var(--k-touch-ripple-color);
  border-radius: 50%;
  margin: 0;
  padding: 0;
  font-size: 0;
  animation: .2s forwards k-touch-ripple-in;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0)scale(0);
  display: block !important;
  position: absolute !important;
}

.k-touch-ripple-wave.k-touch-ripple-wave-out {
  transform: var(--k-ripple-transform);
  animation: .3s forwards k-touch-ripple-out;
}

@keyframes k-touch-ripple-in {
  from {
    transform: translate3d(0, 0, 0)scale(0);
  }

  to {
    transform: var(--k-ripple-transform);
  }
}

@keyframes k-touch-ripple-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

:root {
  --k-safe-area-left: 0px;
  --k-safe-area-right: 0px;
  --k-safe-area-top: 0px;
  --k-safe-area-bottom: 0px;
}

@supports (left: env(safe-area-inset-left)) {
  .safe-areas {
    --k-safe-area-left: env(safe-area-inset-left);
    --k-safe-area-right: env(safe-area-inset-right);
    --k-safe-area-top: env(safe-area-inset-top);
    --k-safe-area-bottom: env(safe-area-inset-bottom);
  }
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.k-ios-preloader > span {
  animation: 1s steps(8, end) infinite k-ios-preloader-spin;
}

.k-material-preloader > span {
  animation: 1.4s linear infinite k-material-preloader-rotate;
}

.k-material-preloader circle {
  stroke-dasharray: 100;
  stroke-dashoffset: 80px;
  stroke-width: 4px;
  transform-origin: 18px 18px;
  animation: 5.6s ease-in-out infinite k-material-preloader-circle-rotate;
  transform: rotate(-90deg);
}

@keyframes k-ios-preloader-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes k-material-preloader-rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes k-material-preloader-circle-rotate {
  0% {
    stroke-dashoffset: 100px;
    transform: rotate(-90deg);
  }

  12.5% {
    stroke-dashoffset: 25px;
    transform: rotate(-90deg);
  }

  25% {
    stroke-dashoffset: 100px;
    transform: rotate(180deg);
  }

  25.1% {
    stroke-dashoffset: 100px;
    transform: rotate(-180deg);
  }

  37.5% {
    stroke-dashoffset: 25px;
    transform: rotate(-180deg);
  }

  50% {
    stroke-dashoffset: 100px;
    transform: rotate(90deg);
  }

  50.1% {
    stroke-dashoffset: 100px;
    transform: rotate(90deg);
  }

  62.5% {
    stroke-dashoffset: 25px;
    transform: rotate(90deg);
  }

  75% {
    stroke-dashoffset: 100px;
    transform: rotate(360deg);
  }

  75.1% {
    stroke-dashoffset: 100px;
    transform: rotate(0);
  }

  87.5% {
    stroke-dashoffset: 25px;
    transform: rotate(0);
  }

  100% {
    stroke-dashoffset: 100px;
    transform: rotate(270deg);
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-1\/2 {
  inset: 50%;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-end-1\.5 {
  inset-inline-end: -.375rem;
}

.-left-4\.75 {
  left: -1.1875rem;
}

.-top-0\.5 {
  top: -.125rem;
}

.-top-1 {
  top: -.25rem;
}

.-top-2 {
  top: -.5rem;
}

.-top-4\.75 {
  top: -1.1875rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-0\.5 {
  bottom: .125rem;
}

.bottom-12 {
  bottom: 3rem;
}

.bottom-40 {
  bottom: 10rem;
}

.bottom-full {
  bottom: 100%;
}

.end-0 {
  inset-inline-end: 0;
}

.end-2 {
  inset-inline-end: .5rem;
}

.end-4 {
  inset-inline-end: 1rem;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-4 {
  left: 1rem;
}

.left-4\.75 {
  left: 1.1875rem;
}

.left-full {
  left: 100%;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.right-full {
  right: 100%;
}

.start-0 {
  inset-inline-start: 0;
}

.start-0\.5 {
  inset-inline-start: .125rem;
}

.start-2 {
  inset-inline-start: .5rem;
}

.start-4 {
  inset-inline-start: 1rem;
}

.top-0 {
  top: 0;
}

.top-0\.5 {
  top: .125rem;
}

.top-1 {
  top: .25rem;
}

.top-1\/2 {
  top: 50%;
}

.top-2 {
  top: .5rem;
}

.top-4 {
  top: 1rem;
}

.top-4\.75 {
  top: 1.1875rem;
}

.top-full {
  top: 100%;
}

.-z-10 {
  z-index: -10;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[200\] {
  z-index: 200;
}

.-m-0\.5 {
  margin: -.125rem;
}

.-m-1 {
  margin: -.25rem;
}

.m-0 {
  margin: 0;
}

.m-2 {
  margin: .5rem;
}

.m-4 {
  margin: 1rem;
}

.m-auto {
  margin: auto;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-my-1 {
  margin-top: -.25rem;
  margin-bottom: -.25rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.\!ms-0 {
  margin-inline-start: 0 !important;
}

.\!mt-0 {
  margin-top: 0 !important;
}

.-mb-2\.5 {
  margin-bottom: -.625rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-me-2 {
  margin-inline-end: -.5rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-ms-2 {
  margin-inline-start: -.5rem;
}

.-ms-3 {
  margin-inline-start: -.75rem;
}

.-mt-2\.5 {
  margin-top: -.625rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mt-px {
  margin-top: -1px;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-\[-18px\] {
  margin-bottom: -18px;
}

.me-1 {
  margin-inline-end: .25rem;
}

.me-1\.5 {
  margin-inline-end: .375rem;
}

.me-2 {
  margin-inline-end: .5rem;
}

.me-3 {
  margin-inline-end: .75rem;
}

.me-4 {
  margin-inline-end: 1rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ms-1 {
  margin-inline-start: .25rem;
}

.ms-2 {
  margin-inline-start: .5rem;
}

.ms-3 {
  margin-inline-start: .75rem;
}

.ms-4 {
  margin-inline-start: 1rem;
}

.ms-auto {
  margin-inline-start: auto;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[60\%\] {
  margin-top: 60%;
}

.mt-auto {
  margin-top: auto;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.\!h-auto {
  height: auto !important;
}

.h-0\.25 {
  height: .0625rem;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-12\.5 {
  height: 3.125rem;
}

.h-13 {
  height: 3.25rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-1em {
  height: 1em;
}

.h-2\.5 {
  height: .625rem;
}

.h-20 {
  height: 5rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-4 {
  height: 1rem;
}

.h-4\.5 {
  height: 1.125rem;
}

.h-5 {
  height: 1.25rem;
}

.h-5\.5 {
  height: 1.375rem;
}

.h-6 {
  height: 1.5rem;
}

.h-6\.5 {
  height: 1.625rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-\[150px\] {
  height: 150px;
}

.h-\[1em\] {
  height: 1em;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[250px\] {
  height: 250px;
}

.h-\[5\.5rem\] {
  height: 5.5rem;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.max-h-0 {
  max-height: 0;
}

.max-h-12 {
  max-height: 3rem;
}

.max-h-40 {
  max-height: 10rem;
}

.max-h-full {
  max-height: 100%;
}

.min-h-28 {
  min-height: 7rem;
}

.min-h-4 {
  min-height: 1rem;
}

.min-h-5 {
  min-height: 1.25rem;
}

.min-h-\[3\.5rem\] {
  min-height: 3.5rem;
}

.min-h-\[56px\] {
  min-height: 56px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0\.5 {
  width: .125rem;
}

.w-1 {
  width: .25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-13 {
  width: 3.25rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-1em {
  width: 1em;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-3\/4 {
  width: 75%;
}

.w-4 {
  width: 1rem;
}

.w-4\.5 {
  width: 1.125rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-5\.5 {
  width: 1.375rem;
}

.w-6 {
  width: 1.5rem;
}

.w-6\.5 {
  width: 1.625rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-\[16\.875rem\] {
  width: 16.875rem;
}

.w-\[19\.5rem\] {
  width: 19.5rem;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[calc\(100\%\+32px\)\] {
  width: calc(100% + 32px);
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-12 {
  min-width: 3rem;
}

.min-w-4 {
  min-width: 1rem;
}

.min-w-5 {
  min-width: 1.25rem;
}

.max-w-\[150px\] {
  max-width: 150px;
}

.max-w-\[66\%\] {
  max-width: 66%;
}

.max-w-\[70\%\] {
  max-width: 70%;
}

.max-w-\[90\%\] {
  max-width: 90%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-shrink, .shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.border-collapse {
  border-collapse: collapse;
}

.origin-bottom {
  transform-origin: bottom;
}

.origin-bottom-left {
  transform-origin: 0 100%;
}

.origin-bottom-right {
  transform-origin: 100% 100%;
}

.origin-left {
  transform-origin: 0;
}

.origin-right {
  transform-origin: 100%;
}

.origin-top-left {
  transform-origin: 0 0;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-2 {
  --tw-translate-x: -.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-2 {
  --tw-translate-x: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-3 {
  --tw-translate-y: .75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-6 {
  --tw-translate-y: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-8 {
  --tw-translate-y: 2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-133 {
  --tw-scale-x: 1.33333;
  --tw-scale-y: 1.33333;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[0\.666\] {
  --tw-scale-x: .666;
  --tw-scale-y: .666;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[0\.85\] {
  --tw-scale-x: .85;
  --tw-scale-y: .85;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-90 {
  --tw-scale-x: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-\[0\.5\] {
  --tw-scale-x: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-y-75 {
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-y-\[2\] {
  --tw-scale-y: 2;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.touch-pan-y {
  --tw-pan-y: pan-y;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.space-x-0\.75 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.1875rem * var(--tw-space-x-reverse));
  margin-left: calc(.1875rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(2px * var(--tw-divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-black > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-divide-opacity));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-divide-opacity));
}

.divide-md-light-outline > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(133 115 109 / var(--tw-divide-opacity));
  border-color: rgb(var(--k-color-md-light-outline) / var(--tw-divide-opacity));
}

.divide-primary > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(250 87 0 / var(--tw-divide-opacity));
  border-color: rgb(var(--k-color-primary) / var(--tw-divide-opacity));
}

.divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .1;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.\!overflow-visible {
  overflow: visible !important;
}

.overflow-visible {
  overflow: visible;
}

.overflow-ellipsis, .text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.\!rounded {
  border-radius: .25rem !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[1\.75rem\] {
  border-radius: 1.75rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-e {
  border-start-end-radius: .25rem;
  border-end-end-radius: .25rem;
}

.rounded-e-full {
  border-start-end-radius: 9999px;
  border-end-end-radius: 9999px;
}

.rounded-e-lg {
  border-start-end-radius: .5rem;
  border-end-end-radius: .5rem;
}

.rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.rounded-s {
  border-start-start-radius: .25rem;
  border-end-start-radius: .25rem;
}

.rounded-s-full {
  border-start-start-radius: 9999px;
  border-end-start-radius: 9999px;
}

.rounded-s-lg {
  border-start-start-radius: .5rem;
  border-end-start-radius: .5rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l {
  border-left-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-none {
  border-style: none;
}

.border-\[\#c8c8cd\] {
  --tw-border-opacity: 1;
  border-color: rgb(200 200 205 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-md-dark-outline {
  --tw-border-opacity: 1;
  border-color: rgb(160 141 134 / var(--tw-border-opacity));
  border-color: rgb(var(--k-color-md-dark-outline) / var(--tw-border-opacity));
}

.border-md-light-on-surface {
  --tw-border-opacity: 1;
  border-color: rgb(32 26 24 / var(--tw-border-opacity));
  border-color: rgb(var(--k-color-md-light-on-surface) / var(--tw-border-opacity));
}

.border-md-light-outline {
  --tw-border-opacity: 1;
  border-color: rgb(133 115 109 / var(--tw-border-opacity));
  border-color: rgb(var(--k-color-md-light-outline) / var(--tw-border-opacity));
}

.border-md-light-primary {
  --tw-border-opacity: 1;
  border-color: rgb(169 56 0 / var(--tw-border-opacity));
  border-color: rgb(var(--k-color-md-light-primary) / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(250 87 0 / var(--tw-border-opacity));
  border-color: rgb(var(--k-color-primary) / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.border-opacity-10 {
  --tw-border-opacity: .1;
}

.border-opacity-20 {
  --tw-border-opacity: .2;
}

.border-opacity-30 {
  --tw-border-opacity: .3;
}

.\!bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important;
}

.\!bg-blue-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity)) !important;
}

.\!bg-gray-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity)) !important;
}

.\!bg-gray-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity)) !important;
}

.\!bg-green-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity)) !important;
}

.bg-\[\#000000bd\] {
  background-color: rgba(0, 0, 0, .74);
}

.bg-\[\#e5e5ea\] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 234 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/10 {
  background-color: rgba(0, 0, 0, .1);
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-current {
  background-color: currentColor;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.bg-ios-light-surface {
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 244 / var(--tw-bg-opacity));
}

.bg-ios-light-surface-1 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-ios-light-surface-2 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 248 / var(--tw-bg-opacity));
}

.bg-ios-light-surface-3 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-ios-light-surface-variant {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 244 / var(--tw-bg-opacity));
}

.bg-md-light-on-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-light-on-primary) / var(--tw-bg-opacity));
}

.bg-md-light-outline {
  --tw-bg-opacity: 1;
  background-color: rgb(133 115 109 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-light-outline) / var(--tw-bg-opacity));
}

.bg-md-light-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(169 56 0 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-light-primary) / var(--tw-bg-opacity));
}

.bg-md-light-primary-container {
  --tw-bg-opacity: 1;
  background-color: rgb(255 219 207 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-light-primary-container) / var(--tw-bg-opacity));
}

.bg-md-light-secondary-container {
  --tw-bg-opacity: 1;
  background-color: rgb(255 219 207 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-light-secondary-container) / var(--tw-bg-opacity));
}

.bg-md-light-surface {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 255 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-light-surface) / var(--tw-bg-opacity));
}

.bg-md-light-surface-1 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 241 242 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-light-surface-1) / var(--tw-bg-opacity));
}

.bg-md-light-surface-2 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 235 235 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-light-surface-2) / var(--tw-bg-opacity));
}

.bg-md-light-surface-3 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 230 227 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-light-surface-3) / var(--tw-bg-opacity));
}

.bg-md-light-surface-5 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 224 219 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-light-surface-5) / var(--tw-bg-opacity));
}

.bg-md-light-surface-variant {
  --tw-bg-opacity: 1;
  background-color: rgb(245 222 214 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-light-surface-variant) / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(250 87 0 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-primary) / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-opacity-15 {
  --tw-bg-opacity: .15;
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-opacity-30 {
  --tw-bg-opacity: .3;
}

.bg-opacity-5 {
  --tw-bg-opacity: .05;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-cover {
  background-size: cover;
}

.fill-black {
  fill: #000;
}

.fill-primary {
  fill: rgb(var(--k-color-primary) / 1);
}

.fill-stone-400 {
  fill: #a8a29e;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.\!px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.\!px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pe-2 {
  padding-inline-end: .5rem;
}

.pe-4 {
  padding-inline-end: 1rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.ps-1 {
  padding-inline-start: .25rem;
}

.ps-12 {
  padding-inline-start: 3rem;
}

.ps-2 {
  padding-inline-start: .5rem;
}

.ps-4 {
  padding-inline-start: 1rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-start {
  text-align: start;
}

.align-middle {
  vertical-align: middle;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-2xs {
  font-size: .625rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[0\.875rem\] {
  font-size: .875rem;
}

.text-\[1\.125rem\] {
  font-size: 1.125rem;
}

.text-\[1\.375rem\] {
  font-size: 1.375rem;
}

.text-\[1\.5rem\] {
  font-size: 1.5rem;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[15px\] {
  font-size: 15px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[17px\] {
  font-size: 17px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[1rem\] {
  font-size: 1rem;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[24px\] {
  font-size: 24px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[34px\] {
  font-size: 34px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-icon-ios {
  font-size: 28px;
}

.text-icon-material {
  font-size: 24px;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-black\/45 {
  color: rgba(0, 0, 0, .45);
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-current {
  color: currentColor;
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-inherit {
  color: inherit;
}

.text-md-light-on-primary {
  --tw-text-opacity: 1;
  color: rgb(var(--k-color-md-light-on-primary) / var(--tw-text-opacity));
}

.text-md-light-on-primary-container {
  --tw-text-opacity: 1;
  color: rgb(var(--k-color-md-light-on-primary-container) / var(--tw-text-opacity));
}

.text-md-light-on-secondary-container {
  --tw-text-opacity: 1;
  color: rgb(var(--k-color-md-light-on-secondary-container) / var(--tw-text-opacity));
}

.text-md-light-on-surface {
  --tw-text-opacity: 1;
  color: rgb(var(--k-color-md-light-on-surface) / var(--tw-text-opacity));
}

.text-md-light-on-surface-variant {
  --tw-text-opacity: 1;
  color: rgb(var(--k-color-md-light-on-surface-variant) / var(--tw-text-opacity));
}

.text-md-light-primary {
  --tw-text-opacity: 1;
  color: rgb(var(--k-color-md-light-primary) / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(var(--k-color-primary) / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-opacity-30 {
  --tw-text-opacity: .3;
}

.text-opacity-40 {
  --tw-text-opacity: .4;
}

.text-opacity-45 {
  --tw-text-opacity: .45;
}

.text-opacity-55 {
  --tw-text-opacity: .55;
}

.text-opacity-75 {
  --tw-text-opacity: .75;
}

.text-opacity-90 {
  --tw-text-opacity: .9;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.placeholder-black::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(0 0 0 / var(--tw-placeholder-opacity));
}

.placeholder-md-light-on-surface-variant::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(var(--k-color-md-light-on-surface-variant) / var(--tw-placeholder-opacity));
}

.placeholder-transparent::placeholder {
  color: rgba(0, 0, 0, 0);
}

.placeholder-opacity-30::placeholder {
  --tw-placeholder-opacity: .3;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-35 {
  opacity: .35;
}

.opacity-45 {
  opacity: .45;
}

.opacity-50 {
  opacity: .5;
}

.opacity-55 {
  opacity: .55;
}

.opacity-80 {
  opacity: .8;
}

.opacity-85 {
  opacity: .85;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-ios-toggle {
  --tw-shadow: 0 2px 4px rgba(0, 0, 0, .3);
  --tw-shadow-colored: 0 2px 4px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.\!outline {
  outline-style: solid !important;
}

.outline {
  outline-style: solid;
}

.outline-offset-0 {
  outline-offset: 0px;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-filter {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform-opacity {
  transition-property: transform, opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-0 {
  transition-duration: 0s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-400 {
  transition-duration: .4s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-\[600ms\] {
  transition-duration: .6s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-material-in {
  transition-timing-function: cubic-bezier(0, .8, .34, 1);
}

.ease-material-in-popup {
  transition-timing-function: cubic-bezier(0, 1, .2, 1);
}

.hairline-t:before {
  background-color: rgba(0, 0, 0, .2);
  background-color: var(--k-hairline-color, rgba(0, 0, 0, .2));
  transition-duration: var(--k-hairline-transition-duration);
  content: "";
  z-index: 10;
  transform-origin: top;
  transform: scaleY(1);
  transform: scaleY(calc(1 / var(--k-device-pixel-ratio, 1)));
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.hairline-t-none:before, .hairline-t-none :not(.hairline-l):before {
  display: none;
}

.hairline-t-scale:before {
  transform: scaleY(2);
}

.hairline-l:before {
  background-color: rgba(0, 0, 0, .2);
  background-color: var(--k-hairline-color, rgba(0, 0, 0, .2));
  transition-duration: var(--k-hairline-transition-duration);
  content: "";
  z-index: 10;
  transform-origin: 0;
  transform: scaleX(1);
  transform: scaleX(calc(1 / var(--k-device-pixel-ratio, 1)));
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hairline-l-none:before, .hairline-l-none :not(.hairline-l):before {
  display: none;
}

.hairline-l-scale:before {
  transform: scaleX(2);
}

.hairline-b:after {
  background-color: rgba(0, 0, 0, .2);
  background-color: var(--k-hairline-color, rgba(0, 0, 0, .2));
  transition-duration: var(--k-hairline-transition-duration);
  content: "";
  z-index: 10;
  transform-origin: bottom;
  transform: scaleY(1);
  transform: scaleY(calc(1 / var(--k-device-pixel-ratio, 1)));
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.hairline-b-scale:after {
  transform: scaleY(2);
}

.hairline-b-none:after, .hairline-b-none :not(.hairline-b):after, .hairline-b-none > * > :after, .last-child-hairline-b-none > :last-child:after, .last-child-hairline-b-none > :last-child :not(.hairline-b):after, .last-child-hairline-b-none > :last-child > * > :after, .last-child-hairline-b-none > :last-child > :after, .last-child-hairline-r-none > :last-child:after, .last-child-hairline-r-none > :last-child :not(.hairline-b):after, .last-child-hairline-r-none > :last-child > * > :after, .last-child-hairline-r-none > :last-child > :after, .first-child-hairline-r-none > :first-child:after, .first-child-hairline-r-none > :first-child :not(.hairline-b):after, .first-child-hairline-r-none > :first-child > * > :after, .first-child-hairline-r-none > :first-child > :after {
  display: none;
}

.hairline-r:after {
  background-color: rgba(0, 0, 0, .2);
  background-color: var(--k-hairline-color, rgba(0, 0, 0, .2));
  transition-duration: var(--k-hairline-transition-duration);
  content: "";
  z-index: 10;
  transform-origin: 100%;
  transform: scaleX(1);
  transform: scaleX(calc(1 / var(--k-device-pixel-ratio, 1)));
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.hairline-r-none:after, .hairline-r-none :not(.hairline-r):after {
  display: none;
}

.hairline-r-scale:after {
  transform: scaleX(2);
}

.touch-ripple-current {
  --k-touch-ripple-color: currentColor;
}

.touch-ripple-black {
  --k-touch-ripple-color: var(--k-touch-ripple-black);
}

.touch-ripple-white {
  --k-touch-ripple-color: var(--k-touch-ripple-white);
}

.touch-ripple-primary {
  --k-touch-ripple-color: rgb(var(--k-color-primary) / var(--k-touch-ripple-opacity));
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.no-safe-areas {
  --k-safe-area-left: 0px;
  --k-safe-area-right: 0px;
  --k-safe-area-top: 0px;
  --k-safe-area-bottom: 0px;
}

.no-safe-areas-top {
  --k-safe-area-top: 0px;
}

.no-safe-areas-left {
  --k-safe-area-left: 0px;
}

.no-safe-areas-right {
  --k-safe-area-right: 0px;
}

.no-safe-areas-bottom {
  --k-safe-area-bottom: 0px;
}

.pt-safe {
  padding-top: 0;
  padding-top: var(--k-safe-area-top);
}

.top-2-safe {
  top: .5rem;
  top: calc(var(--k-safe-area-top)  + .5rem);
}

.top-11-safe {
  top: 2.75rem;
  top: calc(var(--k-safe-area-top)  + 2.75rem);
}

.top-16-safe {
  top: 4rem;
  top: calc(var(--k-safe-area-top)  + 4rem);
}

.pr-safe {
  padding-right: 0;
  padding-right: var(--k-safe-area-right);
}

.pr-2-safe {
  padding-right: .5rem;
  padding-right: calc(var(--k-safe-area-right)  + .5rem);
}

.mr-2-safe {
  margin-right: .5rem;
  margin-right: calc(var(--k-safe-area-right)  + .5rem);
}

.pr-4-safe {
  padding-right: 1rem;
  padding-right: calc(var(--k-safe-area-right)  + 1rem);
}

.mr-4-safe {
  margin-right: 1rem;
  margin-right: calc(var(--k-safe-area-right)  + 1rem);
}

.right-4-safe {
  right: 1rem;
  right: calc(var(--k-safe-area-right)  + 1rem);
}

.pr-6-safe {
  padding-right: 1.5rem;
  padding-right: calc(var(--k-safe-area-right)  + 1.5rem);
}

.pr-8-safe {
  padding-right: 2rem;
  padding-right: calc(var(--k-safe-area-right)  + 2rem);
}

.pb-safe {
  padding-bottom: 0;
  padding-bottom: var(--k-safe-area-bottom);
}

.mb-safe {
  margin-bottom: 0;
  margin-bottom: var(--k-safe-area-bottom);
}

.pb-2-safe {
  padding-bottom: .5rem;
  padding-bottom: calc(var(--k-safe-area-bottom)  + .5rem);
}

.bottom-2-safe {
  bottom: .5rem;
  bottom: calc(var(--k-safe-area-bottom)  + .5rem);
}

.pb-3-safe {
  padding-bottom: .75rem;
  padding-bottom: calc(var(--k-safe-area-bottom)  + .75rem);
}

.mb-14-safe {
  margin-bottom: 3.5rem;
  margin-bottom: calc(var(--k-safe-area-bottom)  + 3.5rem);
}

.bottom-20-safe {
  bottom: 5rem;
  bottom: calc(var(--k-safe-area-bottom)  + 5rem);
}

.bottom-28-safe {
  bottom: 7rem;
  bottom: calc(var(--k-safe-area-bottom)  + 7rem);
}

.pb-3\.5-safe {
  padding-bottom: .875rem;
  padding-bottom: calc(var(--k-safe-area-bottom)  + .875rem);
}

.bottom-12\.5-safe {
  bottom: 3.125rem;
  bottom: calc(var(--k-safe-area-bottom)  + 3.125rem);
}

.pl-safe {
  padding-left: 0;
  padding-left: var(--k-safe-area-left);
}

.pl-2-safe {
  padding-left: .5rem;
  padding-left: calc(var(--k-safe-area-left)  + .5rem);
}

.ml-2-safe {
  margin-left: .5rem;
  margin-left: calc(var(--k-safe-area-left)  + .5rem);
}

.pl-4-safe {
  padding-left: 1rem;
  padding-left: calc(var(--k-safe-area-left)  + 1rem);
}

.ml-4-safe {
  margin-left: 1rem;
  margin-left: calc(var(--k-safe-area-left)  + 1rem);
}

.pl-6-safe {
  padding-left: 1.5rem;
  padding-left: calc(var(--k-safe-area-left)  + 1.5rem);
}

.pl-8-safe {
  padding-left: 2rem;
  padding-left: calc(var(--k-safe-area-left)  + 2rem);
}

.ps-4-safe {
  padding-inline-start: 1rem;
  padding-inline-start: calc(var(--k-safe-area-left)  + 1rem);
}

.pe-4-safe {
  padding-inline-end: 1rem;
  padding-inline-end: calc(var(--k-safe-area-right)  + 1rem);
}

@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  .translucent {
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    --tw-bg-opacity: .8 !important;
  }
}

.touch-pan-y {
  touch-action: pan-y;
}

.no-scrollbar {
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  opacity: 0;
  display: none;
}

.k-color-brand-red {
  --k-color-primary: 255 0 0;
  --k-color-ios-primary: 255 0 0;
  --k-color-ios-primary-tint: 255 41 41;
  --k-color-ios-primary-shade: 214 0 0;
  --k-color-md-light-primary: 192 1 0;
  --k-color-md-light-on-primary: 255 255 255;
  --k-color-md-light-primary-container: 255 218 212;
  --k-color-md-light-on-primary-container: 65 0 0;
  --k-color-md-light-secondary: 119 86 81;
  --k-color-md-light-on-secondary: 255 255 255;
  --k-color-md-light-secondary-container: 255 218 212;
  --k-color-md-light-on-secondary-container: 44 21 18;
  --k-color-md-light-surface: 255 251 255;
  --k-color-md-light-on-surface: 32 26 25;
  --k-color-md-light-surface-variant: 245 221 218;
  --k-color-md-light-on-surface-variant: 83 67 65;
  --k-color-md-light-outline: 133 115 112;
  --k-color-md-light-outline-variant: 216 194 190;
  --k-color-md-light-surface-1: 252 239 242;
  --k-color-md-light-surface-2: 250 231 235;
  --k-color-md-light-surface-3: 248 224 227;
  --k-color-md-light-surface-4: 247 221 224;
  --k-color-md-light-surface-5: 246 216 219;
  --k-color-md-dark-primary: 255 180 168;
  --k-color-md-dark-on-primary: 105 1 0;
  --k-color-md-dark-primary-container: 147 1 0;
  --k-color-md-dark-on-primary-container: 255 218 212;
  --k-color-md-dark-secondary: 231 189 182;
  --k-color-md-dark-on-secondary: 68 41 37;
  --k-color-md-dark-secondary-container: 93 63 59;
  --k-color-md-dark-on-secondary-container: 255 218 212;
  --k-color-md-dark-surface: 32 26 25;
  --k-color-md-dark-on-surface: 237 224 221;
  --k-color-md-dark-surface-variant: 83 67 65;
  --k-color-md-dark-on-surface-variant: 216 194 190;
  --k-color-md-dark-outline: 160 140 137;
  --k-color-md-dark-outline-variant: 83 67 65;
  --k-color-md-dark-surface-1: 43 34 32;
  --k-color-md-dark-surface-2: 50 38 36;
  --k-color-md-dark-surface-3: 57 43 41;
  --k-color-md-dark-surface-4: 59 44 42;
  --k-color-md-dark-surface-5: 63 48 45;
}

.k-color-brand-green {
  --k-color-primary: 0 255 0;
  --k-color-ios-primary: 0 255 0;
  --k-color-ios-primary-tint: 41 255 41;
  --k-color-ios-primary-shade: 0 214 0;
  --k-color-md-light-primary: 2 110 0;
  --k-color-md-light-on-primary: 255 255 255;
  --k-color-md-light-primary-container: 119 255 97;
  --k-color-md-light-on-primary-container: 0 34 0;
  --k-color-md-light-secondary: 84 99 77;
  --k-color-md-light-on-secondary: 255 255 255;
  --k-color-md-light-secondary-container: 215 232 205;
  --k-color-md-light-on-secondary-container: 18 31 14;
  --k-color-md-light-surface: 252 253 246;
  --k-color-md-light-on-surface: 26 28 24;
  --k-color-md-light-surface-variant: 223 228 215;
  --k-color-md-light-on-surface-variant: 67 72 63;
  --k-color-md-light-outline: 115 121 110;
  --k-color-md-light-outline-variant: 195 200 188;
  --k-color-md-light-surface-1: 240 246 234;
  --k-color-md-light-surface-2: 232 242 226;
  --k-color-md-light-surface-3: 225 237 219;
  --k-color-md-light-surface-4: 222 236 216;
  --k-color-md-light-surface-5: 217 233 212;
  --k-color-md-dark-primary: 2 230 0;
  --k-color-md-dark-on-primary: 1 58 0;
  --k-color-md-dark-primary-container: 1 83 0;
  --k-color-md-dark-on-primary-container: 119 255 97;
  --k-color-md-dark-secondary: 187 203 178;
  --k-color-md-dark-on-secondary: 38 52 34;
  --k-color-md-dark-secondary-container: 60 75 55;
  --k-color-md-dark-on-secondary-container: 215 232 205;
  --k-color-md-dark-surface: 26 28 24;
  --k-color-md-dark-on-surface: 226 227 220;
  --k-color-md-dark-surface-variant: 67 72 63;
  --k-color-md-dark-on-surface-variant: 195 200 188;
  --k-color-md-dark-outline: 141 147 135;
  --k-color-md-dark-outline-variant: 67 72 63;
  --k-color-md-dark-surface-1: 25 38 23;
  --k-color-md-dark-surface-2: 24 44 22;
  --k-color-md-dark-surface-3: 23 50 21;
  --k-color-md-dark-surface-4: 23 52 21;
  --k-color-md-dark-surface-5: 23 56 21;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 640px;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.seam-components .seam-device-details .seam-content-header {
  display: none !important;
}

@media (max-width: 640px) {
  .seam-components .seam-table-row .seam-title {
    font-weight: 600;
    line-height: 132%;
    font-size: 14px !important;
  }

  .seam-status-text {
    font-size: 12px !important;
  }

  .seam-components .seam-device-table .seam-table-row .seam-body-cell .seam-bottom .seam-device-model {
    font-weight: 400;
    font-size: 12px !important;
  }

  .seam-components .seam-table-title {
    line-height: 132%;
    font-size: 16px !important;
  }
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:top-1\/2:before {
  content: var(--tw-content);
  top: 50%;
}

.before\:me-1:before {
  content: var(--tw-content);
  margin-inline-end: .25rem;
}

.before\:inline-block:before {
  content: var(--tw-content);
  display: inline-block;
}

.before\:h-0\.75:before {
  content: var(--tw-content);
  height: .1875rem;
}

.before\:h-\[1px\]:before {
  content: var(--tw-content);
  height: 1px;
}

.before\:w-0\.75:before {
  content: var(--tw-content);
  width: .1875rem;
}

.before\:w-1\/4:before {
  content: var(--tw-content);
  width: 25%;
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:bg-gray-300:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.before\:bg-md-light-on-surface-variant:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(83 67 62 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-light-on-surface-variant) / var(--tw-bg-opacity));
}

.before\:align-middle:before {
  content: var(--tw-content);
  vertical-align: middle;
}

.before\:content-\[\'\'\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:right-0:after {
  content: var(--tw-content);
  right: 0;
}

.after\:top-1\/2:after {
  content: var(--tw-content);
  top: 50%;
}

.after\:h-\[1px\]:after {
  content: var(--tw-content);
  height: 1px;
}

.after\:w-1\/4:after {
  content: var(--tw-content);
  width: 25%;
}

.after\:bg-gray-300:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.first\:mx-2:first-child {
  margin-left: .5rem;
  margin-right: .5rem;
}

.first\:mx-4:first-child {
  margin-left: 1rem;
  margin-right: 1rem;
}

.first\:mt-0:first-child {
  margin-top: 0;
}

.first\:mt-2:first-child {
  margin-top: .5rem;
}

.first\:rounded-s:first-child {
  border-start-start-radius: .25rem;
  border-end-start-radius: .25rem;
}

.first\:rounded-t-xl:first-child {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.first\:rounded-bl-xl:first-child {
  border-bottom-left-radius: .75rem;
}

.first\:border-r:first-child {
  border-right-width: 1px;
}

.first\:border-r-0:first-child {
  border-right-width: 0;
}

.first\:px-6:first-child {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.last\:rounded-b-xl:last-child {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.last\:rounded-e:last-child {
  border-start-end-radius: .25rem;
  border-end-end-radius: .25rem;
}

.last\:rounded-br-xl:last-child {
  border-bottom-right-radius: .75rem;
}

.last\:border-l:last-child {
  border-left-width: 1px;
}

.last\:hairline-transparent:last-child {
  --k-hairline-color: transparent;
}

.hover\:bg-black\/5:hover {
  background-color: rgba(0, 0, 0, .05);
}

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.hover\:bg-md-light-secondary-container:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 219 207 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-light-secondary-container) / var(--tw-bg-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.active\:bg-black:active {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.active\:bg-ios-primary-shade:active {
  --tw-bg-opacity: 1;
  background-color: rgb(209 73 0 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-ios-primary-shade) / var(--tw-bg-opacity));
}

.active\:bg-neutral-200:active {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.active\:bg-primary:active {
  --tw-bg-opacity: 1;
  background-color: rgb(250 87 0 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-primary) / var(--tw-bg-opacity));
}

.active\:bg-opacity-10:active {
  --tw-bg-opacity: .1;
}

.active\:bg-opacity-15:active {
  --tw-bg-opacity: .15;
}

.active\:bg-opacity-25:active {
  --tw-bg-opacity: .25;
}

.active\:fill-stone-200:active {
  fill: #e7e5e4;
}

.active\:opacity-100:active {
  opacity: 1;
}

.active\:opacity-20:active {
  opacity: .2;
}

.active\:opacity-30:active {
  opacity: .3;
}

.active\:opacity-55:active {
  opacity: .55;
}

.active\:shadow-lg:active {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.active\:duration-0:active {
  transition-duration: 0s;
}

.active\:hairline-transparent:active {
  --k-hairline-color: transparent;
}

.group:active .group-active\:scale-\[1\.1\] {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.k-material .material\:top-14-safe {
  top: 3.5rem;
  top: calc(var(--k-safe-area-top)  + 3.5rem);
}

.k-ios .ios\:top-11-safe {
  top: 2.75rem;
  top: calc(var(--k-safe-area-top)  + 2.75rem);
}

.range-thumb\:relative::-webkit-slider-thumb {
  position: relative;
}

.range-thumb\:-mt-1\.5::-webkit-slider-thumb {
  margin-top: -.375rem;
}

.range-thumb\:-mt-3\.5::-webkit-slider-thumb {
  margin-top: -.875rem;
}

.range-thumb\:h-3::-webkit-slider-thumb {
  height: .75rem;
}

.range-thumb\:h-7::-webkit-slider-thumb {
  height: 1.75rem;
}

.range-thumb\:w-3::-webkit-slider-thumb {
  width: .75rem;
}

.range-thumb\:w-7::-webkit-slider-thumb {
  width: 1.75rem;
}

.range-thumb\:transform::-webkit-slider-thumb {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.range-thumb\:appearance-none::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
}

.range-thumb\:rounded-full::-webkit-slider-thumb {
  border-radius: 9999px;
}

.range-thumb\:border-none::-webkit-slider-thumb {
  border-style: none;
}

.range-thumb\:bg-md-light-primary::-webkit-slider-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(169 56 0 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-light-primary) / var(--tw-bg-opacity));
}

.range-thumb\:bg-white::-webkit-slider-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.range-thumb\:shadow-ios-toggle::-webkit-slider-thumb {
  --tw-shadow: 0 2px 4px rgba(0, 0, 0, .3);
  --tw-shadow-colored: 0 2px 4px var(--tw-shadow-color);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.range-thumb\:duration-200::-webkit-slider-thumb {
  transition-duration: .2s;
}

.active\:range-thumb\:scale-150::-webkit-slider-thumb:active {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.range-track\:h-px::-webkit-slider-runnable-track {
  height: 1px;
}

.range-track\:appearance-none::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
}

.dark\:divide-md-dark-outline:is(.dark *) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(160 141 134 / var(--tw-divide-opacity));
  border-color: rgb(var(--k-color-md-dark-outline) / var(--tw-divide-opacity));
}

.dark\:border-md-dark-on-surface:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(237 224 220 / var(--tw-border-opacity));
  border-color: rgb(var(--k-color-md-dark-on-surface) / var(--tw-border-opacity));
}

.dark\:border-md-dark-outline:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(160 141 134 / var(--tw-border-opacity));
  border-color: rgb(var(--k-color-md-dark-outline) / var(--tw-border-opacity));
}

.dark\:border-md-dark-primary:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(255 181 155 / var(--tw-border-opacity));
  border-color: rgb(var(--k-color-md-dark-primary) / var(--tw-border-opacity));
}

.dark\:border-white:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.dark\:border-opacity-10:is(.dark *) {
  --tw-border-opacity: .1;
}

.dark\:border-opacity-15:is(.dark *) {
  --tw-border-opacity: .15;
}

.dark\:border-opacity-20:is(.dark *) {
  --tw-border-opacity: .2;
}

.dark\:border-opacity-30:is(.dark *) {
  --tw-border-opacity: .3;
}

.dark\:bg-\[\#1e1e1e\]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 30 30 / var(--tw-bg-opacity));
}

.dark\:bg-\[\#252525\]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(37 37 37 / var(--tw-bg-opacity));
}

.dark\:bg-\[\#323234\]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(50 50 52 / var(--tw-bg-opacity));
}

.dark\:bg-black:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.dark\:bg-ios-dark-surface:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.dark\:bg-ios-dark-surface-1:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(28 28 29 / var(--tw-bg-opacity));
}

.dark\:bg-ios-dark-surface-2:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(18 18 18 / var(--tw-bg-opacity));
}

.dark\:bg-ios-dark-surface-3:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(28 28 29 / var(--tw-bg-opacity));
}

.dark\:bg-ios-dark-surface-variant:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(35 35 35 / var(--tw-bg-opacity));
}

.dark\:bg-md-dark-on-primary:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(91 26 0 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-dark-on-primary) / var(--tw-bg-opacity));
}

.dark\:bg-md-dark-outline:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(160 141 134 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-dark-outline) / var(--tw-bg-opacity));
}

.dark\:bg-md-dark-primary:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 181 155 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-dark-primary) / var(--tw-bg-opacity));
}

.dark\:bg-md-dark-primary-container:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(129 41 0 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-dark-primary-container) / var(--tw-bg-opacity));
}

.dark\:bg-md-dark-secondary-container:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(93 64 53 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-dark-secondary-container) / var(--tw-bg-opacity));
}

.dark\:bg-md-dark-surface:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(32 26 24 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-dark-surface) / var(--tw-bg-opacity));
}

.dark\:bg-md-dark-surface-1:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(43 34 31 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-dark-surface-1) / var(--tw-bg-opacity));
}

.dark\:bg-md-dark-surface-2:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(50 38 34 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-dark-surface-2) / var(--tw-bg-opacity));
}

.dark\:bg-md-dark-surface-3:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(57 43 38 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-dark-surface-3) / var(--tw-bg-opacity));
}

.dark\:bg-md-dark-surface-5:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(63 48 42 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-dark-surface-5) / var(--tw-bg-opacity));
}

.dark\:bg-md-dark-surface-variant:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(83 67 62 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-dark-surface-variant) / var(--tw-bg-opacity));
}

.dark\:bg-neutral-800:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.dark\:bg-primary:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(250 87 0 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-primary) / var(--tw-bg-opacity));
}

.dark\:bg-transparent:is(.dark *) {
  background-color: rgba(0, 0, 0, 0);
}

.dark\:bg-white:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.dark\:bg-white\/10:is(.dark *) {
  background-color: rgba(255, 255, 255, .1);
}

.dark\:bg-opacity-0:is(.dark *) {
  --tw-bg-opacity: 0;
}

.dark\:bg-opacity-10:is(.dark *) {
  --tw-bg-opacity: .1;
}

.dark\:bg-opacity-15:is(.dark *) {
  --tw-bg-opacity: .15;
}

.dark\:bg-opacity-20:is(.dark *) {
  --tw-bg-opacity: .2;
}

.dark\:bg-opacity-30:is(.dark *) {
  --tw-bg-opacity: .3;
}

.dark\:fill-md-dark-primary:is(.dark *) {
  fill: rgb(var(--k-color-md-dark-primary) / 1);
}

.dark\:fill-stone-500:is(.dark *) {
  fill: #78716c;
}

.dark\:text-black:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.dark\:text-md-dark-on-primary:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(var(--k-color-md-dark-on-primary) / var(--tw-text-opacity));
}

.dark\:text-md-dark-on-primary-container:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(var(--k-color-md-dark-on-primary-container) / var(--tw-text-opacity));
}

.dark\:text-md-dark-on-secondary-container:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(var(--k-color-md-dark-on-secondary-container) / var(--tw-text-opacity));
}

.dark\:text-md-dark-on-surface:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(var(--k-color-md-dark-on-surface) / var(--tw-text-opacity));
}

.dark\:text-md-dark-on-surface-variant:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(var(--k-color-md-dark-on-surface-variant) / var(--tw-text-opacity));
}

.dark\:text-md-dark-primary:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(var(--k-color-md-dark-primary) / var(--tw-text-opacity));
}

.dark\:text-white:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.dark\:text-white\/55:is(.dark *) {
  color: rgba(255, 255, 255, .55);
}

.dark\:text-opacity-30:is(.dark *) {
  --tw-text-opacity: .3;
}

.dark\:text-opacity-45:is(.dark *) {
  --tw-text-opacity: .45;
}

.dark\:text-opacity-55:is(.dark *) {
  --tw-text-opacity: .55;
}

.dark\:text-opacity-75:is(.dark *) {
  --tw-text-opacity: .75;
}

.dark\:text-opacity-90:is(.dark *) {
  --tw-text-opacity: .9;
}

.dark\:placeholder-md-dark-on-surface-variant:is(.dark *)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(var(--k-color-md-dark-on-surface-variant) / var(--tw-placeholder-opacity));
}

.dark\:placeholder-white:is(.dark *)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(255 255 255 / var(--tw-placeholder-opacity));
}

.dark\:placeholder-opacity-30:is(.dark *)::placeholder {
  --tw-placeholder-opacity: .3;
}

.dark\:placeholder-opacity-40:is(.dark *)::placeholder {
  --tw-placeholder-opacity: .4;
}

.dark\:touch-ripple-white:is(.dark *) {
  --k-touch-ripple-color: var(--k-touch-ripple-white);
}

.dark\:touch-ripple-primary:is(.dark *) {
  --k-touch-ripple-color: rgb(var(--k-color-primary) / var(--k-touch-ripple-opacity));
}

.before\:dark\:bg-md-dark-on-surface-variant:is(.dark *):before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(216 194 187 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-dark-on-surface-variant) / var(--tw-bg-opacity));
}

.dark\:hover\:bg-md-dark-secondary-container:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(93 64 53 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-dark-secondary-container) / var(--tw-bg-opacity));
}

.dark\:hover\:bg-white\/10:hover:is(.dark *) {
  background-color: rgba(255, 255, 255, .1);
}

.dark\:active\:bg-neutral-700:active:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

.dark\:active\:bg-white:active:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.dark\:active\:bg-opacity-10:active:is(.dark *) {
  --tw-bg-opacity: .1;
}

.dark\:active\:bg-opacity-5:active:is(.dark *) {
  --tw-bg-opacity: .05;
}

.dark\:active\:fill-stone-700:active:is(.dark *) {
  fill: #44403c;
}

.dark\:range-thumb\:bg-md-dark-primary:is(.dark *)::-webkit-slider-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(255 181 155 / var(--tw-bg-opacity));
  background-color: rgb(var(--k-color-md-dark-primary) / var(--tw-bg-opacity));
}

@media (min-width: 640px) {
  .sm\:left-1\/2 {
    left: 50%;
  }

  .sm\:start-auto {
    inset-inline-start: auto;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:rounded-2xl {
    border-radius: 1rem;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .sm\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .sm\:bottom-4-safe {
    bottom: 1rem;
    bottom: calc(var(--k-safe-area-bottom)  + 1rem);
  }

  .sm\:start-4-safe {
    inset-inline-start: 1rem;
    inset-inline-start: calc(var(--k-safe-area-left)  + 1rem);
  }

  .sm\:end-4-safe {
    inset-inline-end: 1rem;
    inset-inline-end: calc(var(--k-safe-area-right)  + 1rem);
  }
}

@media (min-width: 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:end-auto {
    inset-inline-end: auto;
  }

  .md\:start-1\/2 {
    inset-inline-start: 50%;
  }

  .md\:-ms-\[256px\] {
    margin-inline-start: -256px;
  }

  .md\:h-160 {
    height: 40rem;
  }

  .md\:w-160 {
    width: 40rem;
  }

  .md\:w-\[568px\] {
    width: 568px;
  }

  .md\:rounded-\[1\.75rem\] {
    border-radius: 1.75rem;
  }

  .md\:rounded-lg {
    border-radius: .5rem;
  }

  .md\:no-safe-areas {
    --k-safe-area-left: 0px;
    --k-safe-area-right: 0px;
    --k-safe-area-top: 0px;
    --k-safe-area-bottom: 0px;
  }
}

.rtl\:origin-right:where([dir="rtl"], [dir="rtl"] *) {
  transform-origin: 100%;
}

.rtl\:\!-translate-x-5:where([dir="rtl"], [dir="rtl"] *) {
  --tw-translate-x: -1.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.rtl\:translate-x-1\/2:where([dir="rtl"], [dir="rtl"] *) {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rtl\:rotate-180:where([dir="rtl"], [dir="rtl"] *) {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rtl\:\!space-x-reverse:where([dir="rtl"], [dir="rtl"] *) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1 !important;
}

.rtl\:space-x-reverse:where([dir="rtl"], [dir="rtl"] *) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

.rtl\:divide-x-reverse:where([dir="rtl"], [dir="rtl"] *) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 1;
}

.rtl\:hairline-l:where([dir="rtl"], [dir="rtl"] *):before {
  background-color: rgba(0, 0, 0, .2);
  background-color: var(--k-hairline-color, rgba(0, 0, 0, .2));
  transition-duration: var(--k-hairline-transition-duration);
  content: "";
  z-index: 10;
  transform-origin: 0;
  transform: scaleX(1);
  transform: scaleX(calc(1 / var(--k-device-pixel-ratio, 1)));
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.rtl\:first\:rounded-bl-none:first-child:where([dir="rtl"], [dir="rtl"] *) {
  border-bottom-left-radius: 0;
}

.rtl\:first\:rounded-br-xl:first-child:where([dir="rtl"], [dir="rtl"] *) {
  border-bottom-right-radius: .75rem;
}

.rtl\:first\:border-l:first-child:where([dir="rtl"], [dir="rtl"] *) {
  border-left-width: 1px;
}

.rtl\:first\:border-r:first-child:where([dir="rtl"], [dir="rtl"] *) {
  border-right-width: 1px;
}

.rtl\:first\:border-r-0:first-child:where([dir="rtl"], [dir="rtl"] *) {
  border-right-width: 0;
}

.rtl\:first\:border-r-2:first-child:where([dir="rtl"], [dir="rtl"] *) {
  border-right-width: 2px;
}

.rtl\:last\:rounded-bl-xl:last-child:where([dir="rtl"], [dir="rtl"] *) {
  border-bottom-left-radius: .75rem;
}

.rtl\:last\:rounded-br-none:last-child:where([dir="rtl"], [dir="rtl"] *) {
  border-bottom-right-radius: 0;
}

.rtl\:last\:border-l-0:last-child:where([dir="rtl"], [dir="rtl"] *) {
  border-left-width: 0;
}

.rtl\:last\:border-r-0:last-child:where([dir="rtl"], [dir="rtl"] *) {
  border-right-width: 0;
}

.\[\&\:nth-child\(2\)\]\:-mt-12:nth-child(2) {
  margin-top: -3rem;
}

.ngx-json-viewer {
  font-family: monospace;
  font-family: var(--ngx-json-font-family, monospace);
  font-size: 1em;
  font-size: var(--ngx-json-font-size, 1em);
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.ngx-json-viewer .segment {
  margin: 1px 1px 1px 12px;
  padding: 2px;
}

.ngx-json-viewer .segment .segment-main {
  word-wrap: break-word;
}

.ngx-json-viewer .segment .segment-main .toggler {
  vertical-align: middle;
  color: var(--ngx-json-toggler, #787878);
  margin-top: 3px;
  margin-left: -14px;
  font-size: .8em;
  line-height: 1.2em;
  position: absolute;
}

.ngx-json-viewer .segment .segment-main .toggler:after {
  content: "►";
  transition: transform .1s ease-in;
  display: inline-block;
}

.ngx-json-viewer .segment .segment-main .segment-key {
  color: var(--ngx-json-key, #4e187c);
}

.ngx-json-viewer .segment .segment-main .segment-separator {
  color: var(--ngx-json-separator, #999);
}

.ngx-json-viewer .segment .segment-main .segment-value {
  color: var(--ngx-json-value, #000);
}

.ngx-json-viewer .segment .children {
  margin-left: 12px;
}

.ngx-json-viewer .segment-type-string > .segment-main > .segment-value {
  color: var(--ngx-json-string, #ff6b6b);
}

.ngx-json-viewer .segment-type-number > .segment-main > .segment-value {
  color: var(--ngx-json-number, #009688);
}

.ngx-json-viewer .segment-type-boolean > .segment-main > .segment-value {
  color: var(--ngx-json-boolean, #b938a4);
}

.ngx-json-viewer .segment-type-date > .segment-main > .segment-value {
  color: var(--ngx-json-date, #05668d);
}

.ngx-json-viewer .segment-type-array > .segment-main > .segment-value {
  color: var(--ngx-json-array, #999);
}

.ngx-json-viewer .segment-type-object > .segment-main > .segment-value {
  color: var(--ngx-json-object, #999);
}

.ngx-json-viewer .segment-type-function > .segment-main > .segment-value {
  color: var(--ngx-json-function, #999);
}

.ngx-json-viewer .segment-type-null > .segment-main > .segment-value {
  color: var(--ngx-json-null, #fff);
}

.ngx-json-viewer .segment-type-undefined > .segment-main > .segment-value {
  color: var(--ngx-json-undefined, #fff);
}

.ngx-json-viewer .segment-type-null > .segment-main > .segment-value {
  background-color: red;
  background-color: var(--ngx-json-null-bg, red);
}

.ngx-json-viewer .segment-type-undefined > .segment-main > .segment-key {
  color: var(--ngx-json-undefined-key, #999);
}

.ngx-json-viewer .segment-type-undefined > .segment-main > .segment-value {
  background-color: #999;
  background-color: var(--ngx-json-undefined-key, #999);
}

.ngx-json-viewer .segment-type-object > .segment-main, .ngx-json-viewer .segment-type-array > .segment-main {
  white-space: nowrap;
}

.ngx-json-viewer .expanded > .toggler:after {
  transform: rotate(90deg);
}

.ngx-json-viewer .expandable, .ngx-json-viewer .expandable > .toggler {
  cursor: pointer;
}

/*# sourceMappingURL=index.26c735f9.css.map */
